import { Box } from '@mui/material';
import { Container } from '@mui/system';
import BookRoomStepper from 'components/book-room/BookRoomStepper';
import { useRecoilState } from 'recoil';
import { roomBookerHeader } from 'recoil/atoms';

// eslint-disable-next-line import/no-anonymous-default-export
export default function BookRoom() {
  const [header, setHeader] = useRecoilState(roomBookerHeader);
  const changeHeader = function () {
    setHeader('new header');
  };
  return (
    <>
      <Container
        sx={{
          height: '100%',
        }}
        component="main"
        maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <BookRoomStepper />
        </Box>
      </Container>
    </>
  );
}

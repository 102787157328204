import {
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Select } from 'formik-mui';
import { useState } from 'react';

export default function EditGuestForm(props) {
  const [shouldShowOthersTextField, setShouldShowOthersTextField] =
    useState(false);

  const formik = useFormikContext();
  const minDate = moment();
  const getTransportModes = () => {
    return [
      <MenuItem value="bus" key="bus">
        Bus (+S$50)
      </MenuItem>,
      <Divider key="divider">Self-arranged</Divider>,
      <MenuItem value="airplane" key="airplane">
        Airplane
      </MenuItem>,
      <MenuItem value="pteHire" key="pte-hire">
        Private-hire car/bus
      </MenuItem>,
    ];
  };
  const getEdenBranches = () => {
    return [
      <MenuItem key="geylang" value="geylang" variant="body1">
        Geylang
      </MenuItem>,
      <MenuItem key="bishan" value="bishan" variant="body1">
        Bishan
      </MenuItem>,
      <MenuItem key="redhill" value="kent Ridge" variant="body1">
        Redhill
      </MenuItem>,
    ];
  };
  const getNationalities = (index) => {
    const result = [];
    const nationalities = ['Singaporean', 'Malaysian'];
    nationalities.forEach((nationality) => {
      result.push(
        <FormControlLabel
          control={<Radio />}
          onClick={() => {
            setShouldShowOthersTextField(false);
          }}
          onChange={formik.onChange}
          onBlur={formik.onBlur}
          name={`guests.${index}.nationality`}
          key={`${nationality}`}
          value={`${nationality}`}
          label={`${nationality}`}
          checked={formik.values.guests[0].nationality === nationality}
        />
      );
    });
    result.push(
      <FormControlLabel
        name={`guests.${index}.nationality`}
        control={<Radio />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        key="Others"
        onClick={() => {
          setShouldShowOthersTextField(true);
        }}
        value="Others"
        checked={
          Boolean(formik.touched.guests) &&
          Boolean(formik.touched.guests[index]) &&
          Boolean(formik.touched.guests[index].nationality) &&
          Boolean(formik.values.guests[index].nationality !== 'Singaporean') &&
          Boolean(formik.values.guests[index].nationality !== 'Malaysian')
        }
        label={
          <>
            {!shouldShowOthersTextField && (
              <Typography variant="body1" component="div">
                Others
              </Typography>
            )}
            {shouldShowOthersTextField && (
              <TextField
                className="nationality-others"
                fullWidth
                onBlur={(e) => {
                  formik.setFieldValue(
                    `guests[${index}].nationality`,
                    e.target.value
                  );
                  formik.handleBlur(e);
                }}
                onChange={formik.handleChange}
                // autoFocus={Boolean(
                //   formik.values.guests[index].nationality !== 'Singaporean' &&
                //     formik.values.guests[index].nationality !== 'Malaysian'
                // )}
                name={`guests.${index}.nationality`}
                label="Please specify"
              />
            )}
          </>
        }
      />
    );

    return result;
  };
  const getGenders = (index) => {
    const result = [];
    const genders = ['Male', 'Female'];
    genders.forEach((gender) => {
      result.push(
        <FormControlLabel
          control={<Radio />}
          name={`guests.${index}.gender`}
          key={`${gender}`}
          value={`${gender}`}
          label={`${gender}`}
          checked={formik.values.guests[0].gender === gender}
        />
      );
    });
    return result;
  };

  return (
    <>
      <FieldArray name="guests">
        {({ insert, remove, push }) => (
          <>
            {formik.values.guests.length > 0 &&
              formik.values.guests.map((guest, index) => (
                <div key={`guest${index}`}>
                  {
                    <Paper elevation={3} sx={{ padding: 4, marginBottom: 3 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h5" component="span">
                            Edit Occupant
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider textAlign="left">
                            <strong>Personal details</strong>
                          </Divider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={TextField}
                            fullWidth
                            label="Chinese name"
                            name={`guests[${index}].chineseName`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name={`guests[${index}].name`}
                            label="Full name (as in NRIC/Passport)"
                            value={formik.values.guests[index].name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Field
                              component={DesktopDatePicker}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  `guests[${index}].dateOfBirth`,
                                  moment(value)
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              views={['year', 'month', 'day']}
                              name={`guests[${index}].dateOfBirth`}
                              label="Date of birth"
                              value={formik.values.guests[index].dateOfBirth}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl>
                            <FormLabel id="gender">Gender</FormLabel>
                            <Field
                              type="radio"
                              component={RadioGroup}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              children={getGenders(index)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={TextField}
                            fullWidth
                            label="Contact number"
                            name={`guests[${index}].contactNumber`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name={`guests[${index}].passportNumber`}
                            label="Passport number"
                            value={formik.values.guests[index].passportNumber}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Field
                              minDate={minDate}
                              component={DesktopDatePicker}
                              label="Passport Expiry"
                              name={`guests.${index}.passportExpiry`}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  `guests[${index}].passportExpiry`,
                                  moment(value)
                                );
                              }}
                              value={formik.values.guests[index].passportExpiry}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              views={['year', 'month', 'day']}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} s={6}>
                          <FormControl>
                            <FormLabel id="nationality">Nationality</FormLabel>
                            <Field
                              type="radio"
                              component={RadioGroup}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              children={getNationalities(index)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider textAlign="left">
                            <strong>Emergency contact details</strong>
                          </Divider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name={`guests.${index}.emergencyContact`}
                            label="Name"
                            value={formik.values.guests[index].emergencyContact}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name={`guests.${index}.relationship`}
                            label="Relationship"
                            value={formik.values.guests[index].relationship}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name={`guests.${index}.emergencyContactNumber`}
                            label="Contact number"
                            value={
                              formik.values.guests[index].emergencyContactNumber
                            }
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider textAlign="left">
                            <strong>Others</strong>
                          </Divider>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              id="edenBranch"
                              htmlFor={`guests.${index}.edenBranch`}></InputLabel>
                            <Field
                              label="Place of worship"
                              labelId="edenBranch"
                              onChange={formik.onChange}
                              type="text"
                              name={`guests.${index}.edenBranch`}
                              component={Select}
                              variant="outlined"
                              inputProps={{ name: 'tags', id: 'tags' }}
                              children={getEdenBranches()}></Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl sx={{ width: '100%' }}>
                            <InputLabel
                              id="transport"
                              htmlFor={`guests.${index}.transport`}></InputLabel>
                            <Field
                              label="Mode of transport"
                              labelId="transport"
                              onChange={formik.onChange}
                              type="text"
                              name={`guests.${index}.transport`}
                              component={Select}
                              variant="outlined"
                              children={getTransportModes()}></Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name={`guests.${index}.specialRequests`}
                            label="Special Requests"
                            placeholder="Adjoining room, etc."
                            value={formik.values.guests[index].specialRequests}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Paper>
                  }
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </>
  );
}

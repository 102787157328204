import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/Inbox';
import KingBedIcon from '@mui/icons-material/KingBed';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import QRCodeIcon from '@mui/icons-material/QrCode';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import RoomsTable from 'components/admin/RoomsTable';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import TicketTable from './TicketTable';
import UserTable from './UserTable';
import MergerTool from './MergerTool';
import QRGenerator from './QRGenerator';
import MasterForm from './MasterForm';
import { Button } from '@mui/material';
const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [title, setTitle] = useState("Rooms");

  const handleListItemClick = (event, index, text) => {
    setSelectedIndex(index);
    setTitle(text);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Button variant='contained' onClick={() => navigate('/home')}>
          Home
        </Button>
      </Toolbar>
      <Divider />
      <List>
        {['Rooms', 'Participants', 'Tickets', 'QR Generator', 'Merge Rooms'].map((text, index) => (
          <ListItem key={text} disablePadding onClick={event => handleListItemClick(event, index, text)}>
            <ListItemButton>
              <ListItemIcon>
                {index === 0 ? <KingBedIcon /> :
                 index === 1 ? <PeopleIcon /> :
                 index === 2 ? <InboxIcon /> :
                 index === 3 ? <QRCodeIcon /> :
                 index === 4 ? <CallMergeIcon /> :
                 null}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    width: { sm: `calc(100% - ${drawerWidth}px)` },
  }}>
  <Toolbar />
  {title === "Rooms" ? <RoomsTable /> :
   title === "Participants" ? <UserTable /> :
   title === "Tickets" ? <TicketTable /> :
   title === "QR Generator" ? <QRGenerator /> :
   title === "Merge Rooms" ? <MergerTool /> :
   <MasterForm />}
</Box>
    </Box>
  );
}

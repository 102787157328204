import * as React from 'react';
import { 
  DataGrid, 
  GridColDef, 
  GridEventListener, 
  GridToolbarContainer, 
  GridToolbarExport, 
  GridToolbarColumnsButton, 
  GridToolbarFilterButton, 
  GridToolbarDensitySelector 
} from '@mui/x-data-grid';
import { onSnapshot } from 'firebase/firestore';
import { dbref } from 'firebaseFunctions/firebase';
import { useEffect, useState } from 'react';
import EditBookingModal from './EditBookingModal';
import { Typography, Switch, FormControlLabel } from '@mui/material';

// valueGetter helper functions
function getGuestNameForRoom(guestIndex, params) {
  let result = [];
  if (params.row.guests[guestIndex]) {
    result.push(params.row.guests[guestIndex].name);
  } else {
    result = ["N/A"];
  }
  return result.join(", ");
}

function getRoomCost(params) {
  return params.row.roomCost;
}

function getTransportCost(params) {
  return params.row.transportCost;
}

function getTotalCost(params) {
  return params.row.roomCost + params.row.transportCost;
}

function getSpecialRequestForRoom(guestIndex, params) {
  if (params.row.guests && params.row.guests[guestIndex] && params.row.guests[guestIndex].specialRequests) {
    return params.row.guests[guestIndex].specialRequests;
  }
  return "";
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'guest1', headerName: 'Guest 1', width: 200, valueGetter: (params) => getGuestNameForRoom(0, params) },
  { field: 'guest2', headerName: 'Guest 2', width: 200, valueGetter: (params) => getGuestNameForRoom(1, params) },
  { field: 'guest3', headerName: 'Guest 3', width: 200, valueGetter: (params) => getGuestNameForRoom(2, params) },
  { field: 'guest4', headerName: 'Guest 4', width: 200, valueGetter: (params) => getGuestNameForRoom(3, params) },
  { field: 'roomCost', headerName: 'Room Cost', width: 100, valueGetter: (params) => getRoomCost(params) },
  { field: 'transportCost', headerName: 'Transport Cost', width: 100, valueGetter: (params) => getTransportCost(params) },
  { field: 'totalCost', headerName: 'Total Cost', width: 100, valueGetter: (params) => getTotalCost(params) },
  { field: 'extraBed', headerName: 'Extra Bed', width: 100 },
  { field: 'roomType', headerName: 'Room Type', width: 100 },
  { field: 'specialRequest1', headerName: 'Special Request 1', width: 200, valueGetter: (params) => getSpecialRequestForRoom(0, params) },
  { field: 'specialRequest2', headerName: 'Special Request 2', width: 200, valueGetter: (params) => getSpecialRequestForRoom(1, params) },
  { field: 'specialRequest3', headerName: 'Special Request 3', width: 200, valueGetter: (params) => getSpecialRequestForRoom(2, params) },
  { field: 'specialRequest4', headerName: 'Special Request 4', width: 200, valueGetter: (params) => getSpecialRequestForRoom(3, params) },
  { field: 'mobile', headerName: 'Booked by', width: 200 },
  { field: 'verifiedBy', headerName: 'Verified By', width: 100 },
  { field: 'comment', headerName: 'Comment', width: 1000 },
];

export default function RoomsTable() {
  const [rooms, setRooms] = useState([]);
  const [bookingsList, setBookingsList] = useState([]);
  const [showUnpaid, setShowUnpaid] = useState(false);
  const [open, setOpen] = useState(false);
  const [editRoomObject, setEditRoomObject] = useState({});
  const [editUserId, setEditUserId] = useState({});
  const [totalVerifiedCost, setTotalVerifiedCost] = useState(0);

  const calculateTotalVerifiedCost = (rooms) => {
    if (!Array.isArray(rooms)) {
      console.error("rooms is not an array:", rooms);
      return 0;
    }
    const total = rooms
      .filter(room => room && room.verifiedBy && room.verifiedBy.trim() !== '')
      .reduce((total, room) => {
        const roomCost = parseFloat(room.roomCost) || 0;
        const transportCost = parseFloat(room.transportCost) || 0;
        return total + roomCost + transportCost;
      }, 0);
    return total;
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const bookingIndex = (element) => element.mobile === params.row.mobile;
    var rooms = bookingsList[bookingsList.findIndex(bookingIndex)].rooms;
    const roomIndex = (element) => element.bookingId === params.row.bookingId;
    setEditRoomObject(rooms[rooms.findIndex(roomIndex)]);
    setEditUserId(params.row.mobile);
    setOpen(true);
  };

  const handleCloseModal = (event, data) => {
    setOpen(false);
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const processSnapshot = (snapshot) => {
    let accountsList = [];
    let roomsList = [];

    snapshot.docs.forEach((doc) => {
      let docObject = doc.data();
      docObject.id = doc.id;
      accountsList.push(docObject);
    });
    setBookingsList(accountsList);

    accountsList.forEach((booking) => {
      if (booking && booking.rooms && Array.isArray(booking.rooms)) {
        booking.rooms.forEach((room, index) => {
          if (room) {
            room.id = roomsList.length + 1;
            room.mobile = booking.mobile;
            room.roomCost = parseFloat(room.roomCost) || 0;
            room.transportCost = parseFloat(room.transportCost) || 0;
            roomsList.push(room);
          }
        });
      }
    });

    setRooms(roomsList);
    //console.log(roomsList)
    return roomsList;
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(dbref, async (snapshot) => {
      try {
        const processedRooms = await processSnapshot(snapshot);
        if (Array.isArray(processedRooms)) {
          const totalCost = calculateTotalVerifiedCost(processedRooms);
          setTotalVerifiedCost(totalCost);
        } else {
          console.error("processedRooms is not an array:", processedRooms);
          setTotalVerifiedCost(0);
        }
      } catch (error) {
        console.error("Error processing snapshot:", error);
        setTotalVerifiedCost(0);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
          csvOptions={{ 
            fileName: 'room_bookings', 
            delimiter: ',' 
          }} 
        />
      </GridToolbarContainer>
    );
  }

  const getRowClassName = (params) => {
    return params.row.findRoommate ? 'yellowRow' : '';
  };

  const handleToggleChange = (event) => {
    setShowUnpaid(event.target.checked);
  };

  const filteredRooms = showUnpaid ? rooms.filter(room => !room.hasPaid) : rooms.filter(room => room.hasPaid);

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <FormControlLabel
        control={<Switch checked={showUnpaid} onChange={handleToggleChange} />}
        label="Show Unpaid Bookings"
      />
      {showUnpaid && <Typography variant="h1" gutterBottom>UNPAID BOOKINGS</Typography>}
      <DataGrid
  rows={filteredRooms}
  columns={columns}
  paginationModel={paginationModel}
  onPaginationModelChange={setPaginationModel}
  pageSize={25}
  autoHeight
  rowsPerPageOptions={[25]}
  onRowClick={handleRowClick}
  getRowClassName={getRowClassName}
  sx={{
    '& .yellowRow': {
      backgroundColor: 'yellow',
    },
  }}
  components={{
    Toolbar: CustomToolbar,
  }}
/>
      <Typography variant="subtitle1" gutterBottom>
        Yellow Rows Need Roommates
      </Typography>

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Total Verified Cost: ${totalVerifiedCost.toFixed(2)}
      </Typography>

      <EditBookingModal open={open} onCloseModal={handleCloseModal} room={editRoomObject} userId={editUserId}></EditBookingModal>
    </div>
  );
}

import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import RadioButtonsField from 'components/book-room.v2/FormFields/RadioButtonsField';
import { Field, FieldArray, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import { InputField, SelectField } from '../../FormFields';
import FormDivider from '../FormDivider';

const edenBranches = [
  {
    value: 'bishan',
    label: 'Bishan',
  },
  {
    value: 'geylang',
    label: 'Geylang',
  },
  {
    value: 'kent-ridge',
    label: 'Redhill',
  },
  {
    value: 'gy',
    label: 'GY',
  },
];
const genderData = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];
const transportModes = [
  {
    value: 'coach-round-trip',
    label: 'Round-Trip (+$65)',
    parent: 'coach'
  },
  {
    value: 'coach-outgoing',
    label: 'Outgoing (+$40)',
    parent: 'coach'
  },
  {
    value: 'coach-return',
    label: 'Return (+$40)',
    parent: 'coach'
  },
  {
    value: 'car',
    label: 'Car',
    textFieldPlaceHolder: 'Car plate no.',
  },
  {
    value: 'aeroplane',
    label: 'Aeroplane',
    textFieldPlaceHolder: 'Flight no.',
  },
];
const nationalities = [
  {
    value: 'singaporean',
    label: 'Singaporean',
  },
  {
    value: 'malaysian',
    label: 'Malaysian',
  },
  {
    value: 'others',
    label: 'Others',
    textFieldPlaceHolder: 'Please specify',
  },
];

const getTransportButtons = (name, formik) => {
  const coachOptions = transportModes.filter(mode => mode.parent === 'coach');
  const otherModes = transportModes.filter(mode => !mode.parent);

  const renderCoachButtons = () => (
    <div>
      <Typography>Coach</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {coachOptions.map((mode) => (
          <FormControlLabel
            key={mode.value}
            control={<Radio />}
            name={name}
            value={mode.value}
            label={mode.label}
          />
        ))}
      </div>
    </div>
  );

  return (
    <>
      {renderCoachButtons()}
      {otherModes.map((mode) => (
        <FormControlLabel
          key={mode.value}
          control={<Radio />}
          name={name}
          value={mode.value}
          label={
            mode.value === 'car' || mode.value === 'aeroplane' ? (
              <>
                <Typography>{mode.label}</Typography>
                <Field
                  onChange={(e) => {
                    formik.setFieldValue(
                      name,
                      `${mode.label}: ${e.target.value}`
                    );
                  }}
                  placeholder={mode.textFieldPlaceHolder}
                  component={TextField}
                />
              </>
            ) : (
              mode.label
            )
          }
        />
      ))}
    </>
  );
};

const getNationalityButtons = (name, formik) => {
  const nationalityButtons = nationalities.map((nationality, idx) => {
    return (
      <FormControlLabel
        key={nationality.value}
        control={<Radio />}
        name={name}
        value={nationality.value}
        label={
          nationality.value !== 'others' ? (
            nationality.label
          ) : (
            <>
              <Typography>{nationality.label}</Typography>
              <Field
                onChange={(e) => {
                  formik.setFieldValue(
                    name,
                    `Others: ${e.target.value}`
                    // e.target.value
                  );
                }}
                placeholder={nationality.textFieldPlaceHolder}
                component={TextField}></Field>
            </>
          )
        }
      />
    );
  });
  return nationalityButtons;
};

const states = [
  {
    value: undefined,
    label: 'None',
  },
  {
    value: '11',
    label: 'Florida',
  },
  {
    value: '22',
    label: 'Michigan',
  },
  {
    value: '33',
    label: 'Texas',
  },
];

const countries = [
  {
    value: null,
    label: 'None',
  },
  {
    value: '111',
    label: 'United States',
  },
  {
    value: '222',
    label: 'Italy',
  },
  {
    value: '333',
    label: 'Vietnam',
  },
];

export default function AddOccupant(props) {
  const {
    roomType,
    extraBed,
    findRoommate,
    chineseName,
    name,
    dateOfBirth,
    gender,
    contactNumber,
    passportNumber,
    passportExpiry,
    nationality,
    emergencyContact,
    relationship,
    emergencyContactNumber,
    edenBranch,
    transport,
    specialRequests,
  } = props.formField;
  const formik = useFormikContext();

  return (
    <React.Fragment>
      <FieldArray
        name="guests"
        render={(arrayHelpers) => (
          <>
            {formik.values.guests.length > 0 &&
              formik.values.guests.map((guest, index) => (
                <Paper
                  key={guest.id}
                  elevation={3}
                  sx={{ padding: 4, marginBottom: 3 }}>
                  <Typography component="div" variant="h6" gutterBottom>
                    Occupant {index + 1}
                  </Typography>
                  <Grid container spacing={3}>
                    <FormDivider content="Personal Details" />
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${chineseName.name}`}
                        label={chineseName.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${name.name}`}
                        label={name.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Field
                          inputFormat="DD-MM-YYYY"
                          component={DesktopDatePicker}
                          onChange={(value) => {
                            formik.setFieldValue(
                              `guests[${index}].dateOfBirth`,
                              moment(value)
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          views={['year', 'month', 'day']}
                          name={`guests[${index}].${dateOfBirth.name}`}
                          label={dateOfBirth.label}
                          value={formik.values.guests[index].dateOfBirth}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {/* <Grid item xs={12}>
              //For dateOfBirth
             <InputField name={address1.name} label={address1.label} fullWidth />
                  </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <RadioButtonsField
                        data={genderData}
                        // value={`guests[${index}]`}
                        name={`guests[${index}].${gender.name}`}
                        label={gender.label}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${contactNumber.name}`}
                        label={contactNumber.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${passportNumber.name}`}
                        label={passportNumber.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Field
                          fullWidth
                          inputFormat="DD-MM-YYYY"
                          component={DesktopDatePicker}
                          onChange={(value) => {
                            formik.setFieldValue(
                              `guests[${index}].passportExpiry`,
                              moment(value)
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          views={['year', 'month', 'day']}
                          name={`guests[${index}].${passportExpiry.name}`}
                          label={passportExpiry.label}
                          value={formik.values.guests[index].passportExpiry}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {/* <Grid item xs={12}>
              //For passportExpiry
                <InputField name={address1.name} label={address1.label} fullWidth />
              </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <RadioButtonsField
                        customData={getNationalityButtons(
                          `guests[${index}].${nationality.name}`,
                          formik
                        )}
                        name={`guests[${index}].${transport.name}`}
                        label={nationality.label}
                      />
                    </Grid>
                    <FormDivider content="Emergency Contact Details" />
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${emergencyContact.name}`}
                        label={emergencyContact.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${relationship.name}`}
                        label={relationship.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${emergencyContactNumber.name}`}
                        label={emergencyContactNumber.label}
                        fullWidth
                      />
                    </Grid>
                    <FormDivider content="Others" />
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={`guests[${index}].${edenBranch.name}`}
                        label={edenBranch.label}
                        data={edenBranches}
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <SelectField
                        name={`guests[${index}].${transport.name}`}
                        label={transport.label}
                        data={getTransportModes()}
                        fullWidth
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6}>
                      <SelectField
                        name={`guests[${index}].${transport.name}`}
                        label={transport.label}
                        data={states}
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <RadioButtonsField
                        customData={getTransportButtons(
                          `guests[${index}].${transport.name}`,
                          formik
                        )}
                        name={`guests[${index}].${transport.name}`}
                        label={transport.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={`guests[${index}].${specialRequests.name}`}
                        placeholder="Baby cot, adjoining room, etc."
                        label={specialRequests.label}
                        fullWidth
                      />
                    </Grid>
                    {/*         
              <Grid item xs={12}>
                <CheckboxField
                  name={useAddressForPaymentDetails.name}
                  label={useAddressForPaymentDetails.label}
                />
              </Grid> */}
                  </Grid>
                </Paper>
              ))}
          </>
        )}></FieldArray>
    </React.Fragment>
  );
}

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import TextCard from 'components/TicketCard.jsx';
import { resolveTicket,editTicketComment } from 'firebaseFunctions/firebase';
import { PowerInputSharp } from '@mui/icons-material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  
  bgcolor: 'background.paper',
  overflow: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




export default function EditTicketModal(props) {
  
  //props.editRoomObject is passed here
  const [open, setOpen] = React.useState();
  const [title, setTitle] = React.useState();
  const [text, setText] = React.useState()
  const [name, setName] = React.useState()
  const [comment, setComment] = React.useState()
  const [ticketId, setTicketId] = React.useState()

  const resolveTicketOnClick = (event) =>{
   
    resolveTicket(ticketId);
    handleClose()
  }
  const editTicketCommentOnClick = (passedComment) =>{
    editTicketComment(ticketId,passedComment);
    setComment(passedComment);
  }

  const setValues = ()=>{
   
    setTitle(props.ticket.submitted)
    setText(props.ticket.issue)
    setName(props.ticket.name)
    setComment(props.ticket.comment)
    setTicketId(props.ticket.realId)
  }
  // const title=props.ticket.submitted
  // const text =props.ticket.issue
  React.useEffect(() => {
    if(props.ticket){
      setValues()
    
    }
  
    return () => {
    }
  }, [props.ticket])
    

 

  const handleOpen = () => setOpen(true);
  const handleClose = (event) => {
    let data = { name: 'example', type: 'closed from child' };
    props.onCloseModal(event, data);
  };
  
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <TextCard userName={name} title = {title} text = {text} comment ={comment} ticketId={ticketId} resolveTicketOnClick={resolveTicketOnClick} editComment ={editTicketCommentOnClick}></TextCard>
        </Box>

      </Modal>
    </div>
  );
}

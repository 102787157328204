import { Typography, Divider } from "@mui/material"

function createData(roomType, adultPrice, childPrice) {
  return { roomType, adultPrice, childPrice }
}

const normalRoomRows = [
  createData("Single Room", "$600", "NA"),
  createData("Double Room*", "$380", "$260")
]

const addChildRows = [
  createData("Extra Bed Required", "", "$260"),
  createData("No Extra Bed", "", "$150"),
  createData("Age 4 and Below", "", "Free")
]

export const TransportDetails = (
  <>
    <Typography variant="h6">By Coach 巴士</Typography>
    <ul>
      <li>~ 5-7 hrs</li>
      <li>45 seater coach</li>
      <li>Includes lunch</li>
      <li>Bus will depart at 8</li>
    </ul>

    <Divider sx={{ mb: 2 }}></Divider>

    <Typography variant="h6">By Car 自驾</Typography>
    <ul>
      <li>Please arrive before 4pm</li>
      <li>If you arrive early, you can leave your luggage at the ballroom</li>
      <li>Not recommended unless you are familar with driving in Malaysia</li>
    </ul>

    <Divider sx={{ mb: 2 }}></Divider>

    <Typography variant="h6">By Plane 飞机</Typography>
    <ul>
      <li>Please arrive before 4pm</li>
      <li>It takes ~50mins to travel from the airport to the hotel</li>
      <li>If you arrive early, you can leave your luggage at the ballroom</li>
      <li>
        Please double check which terminal you will be departing from
        (KLIA/KLIA2)
      </li>
    </ul>
  </>
)

import {

    signInWithPhoneNumber,
    RecaptchaVerifier
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { useState,useEffect,useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth, addUsers } from "firebaseFunctions/firebase";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import OtpModal from "./OtpModal";
import InputAdornment from '@mui/material/InputAdornment';

const theme = createTheme();






export default function SignInFormPhone() {
    // const [phoneNumber, setPhoneNumber] = useState(countryCode);
    const [expandForm, setExpandForm] = useState(false);
    const [OTP, setOTP] = useState("");
    const [open, setOpen] = useState(true);
    const [otpError,setOtpError] = useState(false);
    const [phoneError,setPhoneError] = useState(false);

    const [verificationState, setVerificationState] = useState(false);
    const navigate = useNavigate();


    const handleClose = () => {
        setOpen(false);
        setExpandForm(false);
        window.location.reload(false);
    }

    const requestOTP = (event) => {
        event.preventDefault();

        const sgRegex = /\+65(8|9)\d{7}/;
        let phoneNumber = "+65" + event.target.phoneNumber.value;
        if (phoneNumber.match(sgRegex)) {


            const appVerifier = (window.recaptchaVerifier = new RecaptchaVerifier(
                "verificationBox",
                {
                    size: "invisible",
                    callback: (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        console.log('recapture solved')
                        setExpandForm(true);


                    },
                },
                auth
            ));

            let phoneNumber = "+65" + event.target.phoneNumber.value;

            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                })
                .catch((error) => {
                    // Error; SMS not sent
                    console.log(error);
                    window.location.reload(false);
                });
        } else {
            setPhoneError(true);
            console.log("invalid phone number");
        }
    }


    const verifyOTP = (event) => {

        let otp = event.target.value;



        if (otp.length === 6) {
            setOTP(otp);
            console.log("OTP REACHED");
            console.log(OTP);
            setVerificationState(true);
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then((result) => {
                console.log('Signed In');
                const user = result.user;
                console.log(user);
                addUsers(user);
                navigate("/home");
            }).catch((error) => {
                console.log(error);
                // User couldn't sign in (bad verification code?)
                // ...
                setVerificationState(false);
                setExpandForm(true);
                setOtpError(true);
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar src="/edenlogo.jpg" sx={{ m: 1, bgcolor: "secondary.main", width: 100, height: 100 }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in / 登录
                    </Typography>
                    <Box component="form" onSubmit={requestOTP} noValidate sx={{ mt: 1 }}>

                        <TextField
                            autoFocus
                            margin="normal"
                            required
                            fullWidth
                            id="phoneNumber"
                            label="Phone Number"
                            name="phoneNumber"
                            error ={phoneError ? true : false }
                            helperText={phoneError ?  "Invalid Phone" :""}
                            inputProps={{ maxLength: 8 }}

                            InputProps={{
                                startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                                inputMode: 'numeric', pattern: '[0-9]*',
                              }
                            }
                        />
                        {expandForm === true ? (
                            <>
                           <OtpModal handleClose={handleClose} open={open} verificationState={verificationState} verifyOTP={verifyOTP} otpError={otpError}></OtpModal>
                            </>
                        ) : (
                            <>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Request/请求 OTP
                                </Button>
                            </>
                        )}

                        <Grid container>
                            <Grid item xs></Grid>
                            <Grid item></Grid>
                        </Grid>
                    </Box>
                </Box>
                <div id="verificationBox"></div>
            </Container>
        </ThemeProvider>
    );

}

import React from 'react';
import SignInForm from 'components/SignInForm';
import SignInFormPhone from 'components/PhoneSignIn';
//<SignInForm />

function SignIn() {
  return (
    <>
      <SignInFormPhone></SignInFormPhone>
    </>
  );
}

export default SignIn;

// eslint-disable-next-line
export default {
  formId: 'bookRoomForm',
  formField: {
    roomType: {
      name: 'roomType',
      label: 'Select Room Type*',
      requiredErrorMsg: 'Required',
    },
    extraBed: {
      name: 'extraBed',
      label: 'Require Extra Bed*',
      requiredErrorMsg: 'NIL',
    },
    findRoommate: {
      name: 'findRoommate',
      label: 'Help Me Find A Roommmate*',
      requiredErrorMsg: 'NIL',
    },
    chineseName: {
      name: 'chineseName',
      label: '中文名*',
      requiredErrorMsg: 'Required',
    },
    name: {
      name: 'name',
      label: 'Name*',
      requiredErrorMsg: 'Required',
    },
    dateOfBirth: {
      name: 'dateOfBirth',
      label: 'Date of Birth',
      requiredErrorMsg: 'Required',
    },
    gender: {
      name: 'gender',
      label: 'Gender',
    },
    contactNumber: {
      name: 'contactNumber',
      label: 'Contact Number',
      requiredErrorMsg: 'Required',
    },
    passportNumber: {
      name: 'passportNumber',
      label: 'Passport Number',
      requiredErrorMsg: 'Required',
    },
    passportExpiry: {
      name: 'passportExpiry',
      label: 'Passport Expiry',
      requiredErrorMsg: 'Required',
    },
    nationality: {
      name: 'nationality',
      label: 'Nationality',
      requiredErrorMsg: 'Required',
    },
    emergencyContact: {
      name: 'emergencyContact',
      label: 'Name Of Emergency Contact',
      requiredErrorMsg: 'Required',
    },
    relationship: {
      name: 'relationship',
      label: 'Relationship Of Emergency Contact',
      requiredErrorMsg: 'Required',
    },
    emergencyContactNumber: {
      name: 'emergencyContactNumber',
      label: 'Contact Number',
      requiredErrorMsg: 'Required',
    },
    edenBranch: {
      name: 'edenBranch',
      label: 'Place Of Worship',
      requiredErrorMsg: 'Required',
    },
    transport: {
      name: 'transport',
      label: 'Mode Of Transport',
      requiredErrorMsg: 'Required',
    },
    specialRequests: {
      name: 'specialRequests',
      label: 'Special Requests',
    },
  },
};

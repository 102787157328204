import { FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material';
import { Field, useField, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-mui';
import { at } from 'lodash';

export default function RadioButtonsField(props) {
  const { label, data, name, customData, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const { setValue } = helper;

  const formik = useFormikContext();

  const getRadioFields = () => {
    if (customData) return customData;

    return data?.map((datum, idx) => {
      const { value, label } = datum;

      const lowerCaseValue = value?.toString().toLowerCase();
      return (
        <FormControlLabel
          key={value}
          control={<Radio />}
          {...rest}
          {...field}
          name={name}
          value={lowerCaseValue}
          label={label}
        />
      );
    });
  };

  return (
    <FormControl component="fieldset" error={isError} {...rest}>
      <FormLabel component="legend">{label}</FormLabel>
      <Field type="radio" component={RadioGroup} children={getRadioFields()} />
    </FormControl>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { v1 as uuidv1 } from 'uuid';

import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from 'firebaseFunctions/firebase';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';

import CheckoutSuccess from './CheckoutSuccess';
import checkoutFormModel from './FormModel/bookRoomFormModel';
import formInitialValues, {
  individualGuestInitialValues,
} from './FormModel/formInitialValues';
import validationSchema from './FormModel/validationSchema';
import AddOccupantForm from './Forms/BookRoomFormAddOccupant';
import SelectRoomForm from './Forms/BookRoomFormSelectRoom';
import BookRoomFormSummary from './Forms/BookRoomFormSummary';
import getRoomCost, {
  calculateNumAdultsAndChildrenForRooming,
} from './FormUtils/getRoomCost';
import getTransportCost from './FormUtils/getTransportCosts';

const steps = ['Select Room', 'Occupant Details', 'Review Booking'];
const { formId, formField } = checkoutFormModel;

export default function BookRoomFormV2(props) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [editEntry, setEditEntry] = React.useState(props.editEntry);

  const [clickedImage, setClickedImage] = React.useState(null);
  const userId = useRecoilValue(phoneNumberAtom);
  const formRef = useRef();
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SelectRoomForm
            clickedImage={clickedImage}
            setClickedImage={setClickedImage}
            formField={formField}
          />
        );
      case 1:
        return <AddOccupantForm formField={formField} />;
      case 2:
        return <BookRoomFormSummary />;
      default:
        return <div>Not Found</div>;
    }
  }

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  if (props.editRoomObject) {
    formInitialValues = props.editRoomObject;
    if (editEntry) {
      setEditEntry(false);
      setActiveStep(1);
    }
  }
  const saveBookingToDB = async (formValues) => {
    //Calculate room cost:
    const roomCost = getRoomCost(formValues);

    const transportCost = getTransportCost(formValues);

    //Convert dates to utc string else firebase won't accept
    formValues.guests.forEach((guest) => {
      guest.dateOfBirth = Timestamp.fromDate(guest.dateOfBirth.toDate());
      guest.passportExpiry = Timestamp.fromDate(guest.passportExpiry.toDate());

      // guest.dateOfBirth = moment.utc(guest.dateOfBirth).format();
      // guest.passportExpiry = moment.utc(guest.passportExpiry).format();
    });

    //save the booking in the DB
    try {
      const dbRef = doc(db, 'account', userId);
      const currDocData = (await getDoc(dbRef)).data();
      const newRoomsList = [
        ...currDocData.rooms,
        {
          ...formValues,
          bookingId: uuidv1(),
          transportCost: transportCost,
          roomCost: roomCost,
          // userId: data.userId,
          hasPaid: false,
          verifiedPayment: false,
        },
      ];
      await updateDoc(dbRef, {
        rooms: newRoomsList,
      });
    } catch (error) {
      console.error(error);
    }
  };
  async function submitForm(values, actions) {
    // await _sleep(1000);
    //alert(JSON.stringify(values, null, 2));
    saveBookingToDB(values);
    actions.setSubmitting(false);
    navigate('/home');

    setActiveStep(activeStep + 1);
  }

  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      if (activeStep === 0) {
        setNumOfOccupantsInRoom(formRef.current.values);
      }
      if (activeStep === 1) {
        const { ofAge, underage } = calculateNumAdultsAndChildrenForRooming(values.guests);
        const moreThanThreeAdults = ofAge > 3;
        const noAdultInRoom = ofAge === 0;
        const moreThanTwoChildren = underage > 2;
        
        if (noAdultInRoom) {
            alert('Please follow our rooming policy before proceeding:\n1. There must be at least 1 adult in each room.');
            actions.setSubmitting(false);
            return;
        } else if (moreThanThreeAdults) {
            alert('Please follow our rooming policy before proceeding:\n1. There can be a maximum of 3 adults in a room.');
            actions.setSubmitting(false);
            return;
        } else if (moreThanTwoChildren) {
            alert('Please follow our rooming policy before proceeding:\n1. There can be a maximum of 2 children in a room.');
            actions.setSubmitting(false);
            return;
        } else if (ofAge === 3) {
          // If there are 3 adults, set extraBed to true
          values.extraBed = true;
      }
      }
  
      setActiveStep((currStep) => currStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep((currStep) => currStep - 1);
  }

  const getNumOccupants = (formikValues) => {
    if (formikValues.findRoommate == true) {
      
      return 1;
    }else if (formikValues.roomType==="Single"){
      return 1;
    }

    const extraOccupant = formikValues.extraBed ? 1 : 0;
    return 2 + extraOccupant;
  };
  const setNumOfOccupantsInRoom = (formikValues) => {
    const numOccupants = formikValues.occupants;
    const currNumOfOccupantsInForm = formikValues.guests.length;
  
    const numOccupantsToRemove = Math.max(0, currNumOfOccupantsInForm - numOccupants);
    const shouldNeitherAddNorRemoveOccupants = numOccupants === currNumOfOccupantsInForm;
    const shouldRemoveOccupants = numOccupantsToRemove > 0;
    const shouldAddOccupants = !shouldRemoveOccupants && !shouldNeitherAddNorRemoveOccupants;
  
    if (shouldNeitherAddNorRemoveOccupants) return;
    else if (shouldRemoveOccupants) {
      formikValues.guests.splice(numOccupants, numOccupantsToRemove);
    } else if (shouldAddOccupants) {
      for (let i = 0; i < numOccupants - currNumOfOccupantsInForm; i += 1) {
        formikValues.guests.push(individualGuestInitialValues());
      }
    }
  };

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Checkout
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        // className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (index < activeStep) setActiveStep(index);
            }}
            key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
            validateOnChange={false}
            innerRef={formRef}
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form id={formId}>
                {renderStepContent(activeStep)}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 2,
                    mb: 4,
                  }}>
                  {activeStep !== 0 && (
                    <Button
                      sx={{ mr: 1 }}
                      onClick={_handleBack}
                      // className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  <Box sx={{ flex: '1 1 auto' }} />
                  <div>
                    <Button
                      disabled={
                        isSubmitting ||
                        (activeStep === 0 && !Boolean(clickedImage))
                      }
                      type="submit"
                      variant="contained"
                      color="primary">
                      {isLastStep ? 'Add to cart' : 'Next'}
                    </Button>
                    {isSubmitting && <CircularProgress size={24} />}
                  </div>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

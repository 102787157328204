import { AuthProvider } from 'context/AuthContext';
import { auth } from 'firebaseFunctions/firebase';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AppRoutes from 'routes';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      setLoading(false);
    });
    return unsubscribe;
  }, []);

  return (
    <React.StrictMode>
      <RecoilRoot>
        <React.Suspense fallback={<div>Loading...</div>}>
          {!loading && (
            <>
              <BrowserRouter>
                <AuthProvider>
                  {/* <Navbar /> */}
                  <AppRoutes />
                </AuthProvider>
              </BrowserRouter>
            </>
          )}
        </React.Suspense>
      </RecoilRoot>
    </React.StrictMode>
  );
}

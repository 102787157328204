// components/HomePageButton.js
import { Button } from '@mui/material';
import React from 'react';

export default function HomePageButton({ color, content, clickHandler, disabled }) {
  return (
    <Button
      variant="contained"
      color={color}
      onClick={clickHandler}
      disabled={disabled} // Ensure the disabled prop is applied here
      fullWidth
    >
      {content}
    </Button>
  );
}

import {

    signInWithPhoneNumber,
    RecaptchaVerifier
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { useState,useEffect,useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth, addUsers } from "firebaseFunctions/firebase";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '75vw',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function ChildrenWorshipModal(props){
   
    const navigate = useNavigate();

    return(
        <Modal
        open={props.open}
        onClose={props.onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    ><Box sx={modalStyle}>
            <Typography>营会的儿童事工在寻找老师/帮手，要是您对儿童事工有负担，请在此表达兴趣!</Typography>
            <Typography>We're looking for teachers for the Children's worship for the duration of the camp. If you are interested in serving this camp, please express interest here!</Typography>
            <Box sx={{textAlign:'center',mt:2}}><Button onClick={()=>{navigate('/children-worship')}} variant="contained">Apply now</Button></Box>
        </Box>
    </Modal>
    )
}
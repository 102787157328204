import { atom } from 'recoil';
/**
 * Docs: https://recoiljs.org/docs/introduction/getting-started
 * 
 * Helpful articles: www.freecodecamp.org/news/how-to-use-recoil-for-state-management-in-your-react-projects/
Best practices for structuring Recoil store: https://wes-rast.medium.com/recoil-project-structure-best-practices-79e74a475caa 
*/

export const roomBookerHeader = atom({
  key: 'roomBookerHeader',
  default: 'Welcome to the room booker',
});
export const cart = atom({
  key: 'cart',
  default: [],
});

import * as React from "react";
import Typography from "@mui/material/Typography";


export default function RoomCardNameList(props){
    return (
      <>
      <Typography variant="body2" color="text.secondary">
        Guest {props.index} : {props.name}
    </Typography>
      </>
    );
  }
  

import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography
  } from "@mui/material"
  
  function createData(roomType, adultPrice, childPrice) {
    return { roomType, adultPrice, childPrice }
  }
  
  const normalRoomRows = [
    createData("1 Adult", "$600", "NA"),
    createData("2 Adults", "$380", "$260")
  ]
  
  const addChildRows = [
    createData("Extra Bed Required*", "", "$260"),
    createData("No Extra Bed", "", "$150"),
    createData("Age 4 and Below", "", "Free")
  ]
  
  export const AccomodationDetails = (
    <>
      Camp Fees Includes:
      <ul>
        <li>Hotel accommodation for 4-Day / 3-Night stay</li>
        <li>Meals - 3x breakfast, 2x lunch, 3x dinner, coffee break snacks</li>
      </ul>
      <br />
      <TableContainer component={Paper} elevation='3'>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Per Room (Twin/King)</TableCell>
              <TableCell align="right">Per Person</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {normalRoomRows.map(row => (
              <TableRow
                key={row.roomType}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.roomType}
                </TableCell>
                <TableCell align="right">{row.adultPrice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
            </Table>
          </TableContainer>
           <br/>
      <TableContainer component={Paper} elevation='3'>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Add-ons for families with children (12 and below):</TableCell>
              <TableCell align="right">Per Child</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addChildRows.map(row => (
              <TableRow
                key={row.roomType}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.roomType}
                </TableCell>
                <TableCell align="right">{row.childPrice}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}>
                *Only one extra bed is allowed per room.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
  
import { Divider, Grid } from '@mui/material';

export default function FormDivider({ content, alignText = 'center' }) {
  return (
    <Grid item xs={12}>
      <Divider textAlign={alignText}>
        <strong>{content}</strong>
      </Divider>
    </Grid>
  );
}

import { FormControlLabel,FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Field, useFormikContext } from 'formik';


const images = [
  {
    url: '/LeMeridianKingRoom.jpeg',
    title: 'King',
    width: '50%',
  },
  {
    url: '/LeMeridianTwinRoom.jpeg',
    title: 'Twin',
    width: '50%',
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function BookRoomStepperFormSelectRoomType({
  clickedImage,
  setClickedImage,
}) {
  const formik = useFormikContext();

  const handleImageClick = (image) => () => {
    setClickedImage(image.title);
    formik.values.roomType = image.title;
    formik.values.findRoommate = false;
  };
  return (
    // <Box
    //   sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
    <Grid container>
      {images.map((image) => (
        <Grid
          key={image.title}
          sm={6}
          xs={12}
          item
          sx={{
            p: 2,
          }}>
          <ImageButton
            onClick={handleImageClick(image)}
            focusRipple
            key={image.title}
            style={{
              width: '100%',
              border:
                image.title === clickedImage ? '4px solid #1875D1' : 'none',
            }}>
            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
            <ImageBackdrop
              style={{
                opacity: image.title === clickedImage ? '0' : '0.4',
              }}
              className="MuiImageBackdrop-root"
            />
            {!(image.title === clickedImage) && (
              <Image>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}>
                  {image.title}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            )}
          </ImageButton>
        </Grid>
      ))}
       <Grid item xs={12} sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="occupants-label">Number of Occupants</InputLabel>
          <Field
            as={Select}
            labelId="occupants-label"
            id="occupants"
            name="occupants"
            label="Number of Occupants"
            defaultValue=""
            onChange={formik.handleChange}
            value={formik.values.occupants || ''}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <div>Additional options</div>
        <Field
          disabled={formik.values.roomType !== 'Twin'}
          as={FormControlLabel}
          onChange={(e) => {
            formik.handleChange(e);
            if (formik.values.findRoommate == false)
              formik.values.extraBed = false;
          }}
          type="checkbox"
          name="findRoommate"
          control={<Checkbox name="findRoommate" />}
          label="Find me a roommate"
        />
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="extraBed"
          disabled={formik.values.findRoommate == true}
          control={<Checkbox name="extraBed" />}
          label="加床，方可只能是儿童（十二岁以下）/ Add an extra bed? (The occupant must be a child up to the age of 12)" 
        />

        {/* 
        ========
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="findRoommate"
          control={
            <Checkbox
              name="findRoommate"
              // value={'findRoommate'}
              // checked={formik.values.findRoomate}
              disabled={formik.values.roomType !== 'Twin'}
              onChange={(value) => {
                formik.setFieldValue(
                  'findRoommate',
                  !formik.values.findRoommate
                );
              }}
            />
          }
          label="Help me find a roommate"
        /> 
        ===================
        */}
      </Grid>
    </Grid>
    // </Box>
  );
}

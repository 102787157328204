import { CssBaseline, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from './styles';

export default function MaterialLayout(props) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
      // className={
      //   classes.root}
      >
        <Paper
        // className={classes.paper}
        >
          {children}
        </Paper>
      </div>
      
    </ThemeProvider>
  );
}

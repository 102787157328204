import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { v1 as uuidv1 } from 'uuid';

import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'firebaseFunctions/firebase';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';
import FormCompletedSuccessfully from '../FormCompletedSuccessfully';
import BookRoomStepperFormAddOccupants from './BookRoomStepperFormAddOccupants';
import BookRoomStepperFormSelectRoomType from './BookRoomStepperFormSelectRoomType';
import BookRoomStepperFormSummary from './BookRoomStepperFormSummary';
import validationSchema from './validationSchema';

const steps = ['Select room type', 'Add occupants', 'Summary'];

export default function BookRoomForm(props) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [clickedImage, setClickedImage] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [editEntry, setEditEntry] = React.useState(props.editEntry);

  const [shouldDisplaySubmitSpinner, setShouldDisplaySubmitSpinner] =
    React.useState(false);
  function isFinalStep() {
    return activeStep === steps.length - 1;
  }

  const getButtonContent = (isSubmitting) => {
    if (activeStep !== steps.length - 1) return 'Next';
    else {
      if (isSubmitting) {
        return <CircularProgress size={24} />;
      } else {
        return 'Add to cart';
      }
    }
  };

  const getNumOccupants = (formikValues) => {
    if (formikValues.findRoommate == true) {
      return 1;
    }

    const extraOccupant = formikValues.extraBed ? 1 : 0;
    return 2 + extraOccupant;
  };
  const setNumOfOccupantsInRoom = (formikValues) => {
    const defaultNumOccupants = 2;
    const numOccupantsBasedOnUserInput = getNumOccupants(formikValues);

    const currNumOfOccupantsInForm = formikValues.guests.length;
    //Determine how many excess occupants there are
    const numOccupantsToRemove = Math.max(
      0,
      currNumOfOccupantsInForm - numOccupantsBasedOnUserInput
    );
    const shouldNeitherAddNorRemoveOccupants =
      numOccupantsBasedOnUserInput === currNumOfOccupantsInForm;
    const shouldRemoveOccupants = numOccupantsToRemove > 0;
    const shouldAddOccupants =
      !shouldRemoveOccupants && !shouldNeitherAddNorRemoveOccupants;
    //Handle remove occupants
    if (shouldNeitherAddNorRemoveOccupants) return;
    else if (shouldRemoveOccupants) {
      formikValues.guests.splice(
        //-1 becos arrays are 0-indexed
        numOccupantsBasedOnUserInput - numOccupantsToRemove - 1
      );
    } else if (shouldAddOccupants) {
      for (
        let i = 0;
        i < numOccupantsBasedOnUserInput - currNumOfOccupantsInForm;
        i += 1
      ) {
        formikValues.guests.push({ ...singleGuestInitialValues });
      }
    }
    // formikValues.guests.splice(1, 0, {
    //   ...singleGuestInitialValues,
    // });
  };
  const phoneNumber = useRecoilValue(phoneNumberAtom);

  const handleNext = (formikValues) => () => {
    if (activeStep === 0) {
      setNumOfOccupantsInRoom(formikValues);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const getRoomCost = (formValues) => {
    const COST_PER_ADULT = formValues.roomType === "Single" ? 600 : 380;
    const COST_PER_CHILD = 260;
    const COST_PER_CHILD_ON_EXTRA_BED = 150;
    let roomCost = COST_PER_ADULT * 2;
    const numOccupants = formValues.guests.length;
    let numChildren = formValues.guests.reduce((prevVal, guest) => {
      const age = moment().diff(guest.dateOfBirth, 'years');
      const isChild = age <= 12;
      return prevVal + isChild ? 1 : 0;
    }, 0);
    const numAdults = numOccupants - numChildren;

    if (formValues.extraBed) {
      if (numChildren === 1)
        roomCost = 2 * COST_PER_ADULT + COST_PER_CHILD_ON_EXTRA_BED;
      if (numChildren == 2)
        roomCost =
          COST_PER_ADULT + COST_PER_CHILD + COST_PER_CHILD_ON_EXTRA_BED;
    } else {
      // Else there is no extra bed
      if (numChildren === 1) roomCost = COST_PER_ADULT + COST_PER_CHILD;
      else if (numAdults === 2) roomCost = COST_PER_ADULT * 2;
    }
    return roomCost;
  };
  const getTransportCost = (formValues) => {
    return formValues.guests.reduce((prevValue, guest) => {
      const requiresTransport =
        guest.transport.toString().toUpperCase() === 'BUS';
      return prevValue + requiresTransport ? 50 : 0;
    }, 0);
  };
  const saveBookingToDB = async (formValues) => {
    //Calculate room cost:
    const roomCost = getRoomCost(formValues);

    const transportCost = getTransportCost(formValues);

    //Convert dates to utc string else firebase won't accept
    formValues.guests.forEach((guest) => {
      guest.dateOfBirth = moment.utc(guest.dateOfBirth).format();
      guest.passportExpiry = moment.utc(guest.passportExpiry).format();
    });

    //save the booking in the DB
    const accountRef = collection(db, 'account');
    const accountQuery = query(accountRef, where('mobile', '==', phoneNumber));
    try {
      const response = await getDocs(accountQuery);
      response.forEach((document) => {
        const data = document.data();
        const newRoomsList = [
          ...data.rooms,
          {
            ...formValues,
            bookingId: uuidv1(),
            transportCost: transportCost,
            roomCost: roomCost,
            userId: data.userId,
            hasPaid: false,
          },
        ];
        const newDocRef = doc(db, 'account', data.userId);
        updateDoc(newDocRef, {
          rooms: newRoomsList,
        });
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const singleGuestInitialValues = {
    chineseName: '',
    name: '',
    dateOfBirth: moment(),
    gender: '',
    contactNumber: '',
    passportNumber: '',
    passportExpiry: moment(),
    nationality: '',
    emergencyContact: '',
    relationship: '',
    emergencyContactNumber: '',
    edenBranch: '',
    transport: '',
    specialRequests: '',
  };
  //Must tally with labelMap in BookRoomFormSummary
  let initialValues = {
    roomType: '',
    extraBed: false,
    findRoommate: false,
    guests: [{ ...singleGuestInitialValues }, { ...singleGuestInitialValues }],
  };
  if (props.editRoomObject) {
    initialValues = props.editRoomObject;
    if (editEntry) {
      setEditEntry(false);
      setActiveStep(1);
    }
  }
  const handleFormSubmit = (values, actions) => {
    saveBookingToDB(values);
    actions.setSubmitting(false);
    navigate('/home');

    // setTimeout(() => {
    //   alert(JSON.stringify(values, null, 2));
    //   actions.setSubmitting(false);
    // }, 1000);
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}>
      <Stepper
        sx={{
          marginBottom: '32px',
        }}
        activeStep={activeStep}
        alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              onClick={() => {
                if (index < activeStep) setActiveStep(index);
              }}
              sx={{ cursor: 'pointer' }}
              key={label}
              {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <FormCompletedSuccessfully handleReset={handleReset} />
      ) : (
        <React.Fragment>
          <Formik
            // enableReinitialize
            validateOnBlur={true}
            validationSchema={validationSchema}
            validateOnChange={false}
            initialValues={{ ...initialValues }}
            onSubmit={handleFormSubmit}>
            {({ values, errors, isSubmitting, touched, validateForm }) => (
              <Box
                sx={{
                  width: '100%',
                  overflow: 'contain',
                }}>
                <Form>
                  {activeStep === 0 && (
                    <BookRoomStepperFormSelectRoomType
                      clickedImage={clickedImage}
                      setClickedImage={setClickedImage}
                    />
                  )}
                  {activeStep === 1 && <BookRoomStepperFormAddOccupants />}
                  {activeStep === 2 && <BookRoomStepperFormSummary />}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      pt: 2,
                      mb: 4,
                    }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}>
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isFinalStep() && (
                      <Button disabled={isSubmitting} type="submit">
                        Add to cart
                      </Button>
                    )}
                    {!isFinalStep() && (
                      <Button
                        disabled={activeStep === 0 && !Boolean(clickedImage)}
                        onClick={handleNext(values)}>
                        {getButtonContent(isSubmitting)}
                      </Button>
                    )}
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
        </React.Fragment>
      )}
    </Box>
  );
}

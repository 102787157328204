import React from 'react';
import PersistentDrawer from 'components/admin/PersistentDrawer'
import RoomsTable from 'components/admin/RoomsTable';
import UserTable from 'components/admin/UserTable';

function Admin() {
  return (
    <>
    <PersistentDrawer></PersistentDrawer>
    </>
  );
}

export default Admin;

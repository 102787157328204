import { Navigate, Outlet,useLocation } from 'react-router-dom'
import { useState,useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useAuth } from 'context/AuthContext';


const PrivateRoutes = () => {
    const  currentUser  = useAuth();
    const location = useLocation();
    if (currentUser.currentUser === undefined) return null; // or loading spinner, etc...
  
    return currentUser.currentUser
      ? <Outlet />
      : <Navigate to="/sign-in" replace state={{from:location}} />;
  }

export default PrivateRoutes;
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import EditGuestForm from './EditGuestForm';
import { Form, Formik } from 'formik';
import {Button} from '@mui/material'
import {Timestamp} from 'firebase/firestore'

let initialValues={};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '90vh',
  bgcolor: 'background.paper',
  overflow: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function EditGuestModal(props) {
  const handleFormSubmit = (values, actions) => {
    
    values.guests[0].dateOfBirth = Timestamp.fromDate(values.guests[0].dateOfBirth.toDate());
    values.guests[0].passportExpiry = Timestamp.fromDate(values.guests[0].passportExpiry.toDate());
    
    
    const editGuestObj=values.guests[0];
    props.updateUserOnClick(editGuestObj,props.editGuest);
    
   
  };

  const singleGuestInitialValues = {
    chineseName: props.editGuest.chineseName,
    name: props.editGuest.name,
    dateOfBirth: props.editGuest.dateOfBirth,
    gender: String(props.editGuest.gender),
    contactNumber: props.editGuest.contactNumber,
    passportNumber: props.editGuest.passportNumber,
    passportExpiry: props.editGuest.passportExpiry,
    nationality: String(props.editGuest.nationality),
    emergencyContact: props.editGuest.emergencyContact,
    relationship: props.editGuest.relationship,
    emergencyContactNumber:props.editGuest.emergencyContactNumber,
    edenBranch: props.editGuest.edenBranch,
    transport: props.editGuest.transport,
    specialRequests: props.editGuest.specialRequests,
  };
  let initialValues = {
    roomType: '',
    extraBed: false,
    findRoommate: false,
    guests: [ singleGuestInitialValues ],
  };
  
  
  
  //props.editRoomObject is passed here
  const [open, setOpen] = React.useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = (event) => {
    let data = { name: 'example', type: 'closed from child' };
    props.onCloseModal(event, data);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Formik
            // enableReinitialize
           
            initialValues={{ ...initialValues }}
            onSubmit={handleFormSubmit}>
            {({ values, errors, isSubmitting, touched, validateForm }) => (
              <Box
                sx={{
                  width: '100%',
                  overflow: 'contain',
                }}>
                <Form>
                <EditGuestForm  />
                <Button disabled={isSubmitting} type="submit">
                        Edit
                      </Button>
                </Form>
              </Box>
            )}
          </Formik>
</Box>
      </Modal>
    </div>
  );
}

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import RoomCardNameList from 'components/view-bookings/RoomCardNameList';
import * as React from 'react';

class GuestDetails {
  constructor(name = 'john') {
    this.name = name;
  }
}
const style = {
  flexDirection: 'column',
};

function createGuestList(guests) {
  const createGuestDetailsList = () => {
    let guestList = [];
    guests.forEach((guest) => {
      const guestName = new GuestDetails(guest.name);
      guestList.push(guestName);
    });

    return guestList;
  };

  const guestDetailsList = createGuestDetailsList(guests);
  return guestDetailsList.map((guestDetail, index) => (
    <React.Fragment key={guestDetail.name}>
      <Box sx={{ width: '100%' }}>
        <RoomCardNameList
          name={guestDetail.name}
          index={index + 1}></RoomCardNameList>
      </Box>
    </React.Fragment>
  ));
}

export default function MediaCard({
  title,
  price,
  guests,
  verifiedPayment,
  roomType,
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        sx={{
          maxWidth: 352,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: '-webkit-center',
        }}>
        <CardMedia
          component="img"
          height="140"
          image={
            roomType === 'King'
              ? '/LeMeridianKingRoom.jpeg'
              : '/LeMeridianTwinRoom.jpeg'
          }
          alt="KingRoom"
        />
        <CardContent sx={{ columnCount: 1 }}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          {createGuestList(guests)}
          <Typography gutterBottom variant="h5" component="div" sx={{ mt: 4 }}>
            Room Cost: {price}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography
            gutterBottom
            variant="h8"
            component="div"
            sx={{
              padding: 5,
              textAlign: 'center',
              border: '1px',
              borderStyle: 'Solid',
            }}>
            {verifiedPayment
              ? '您的付款已通过验证。Your payment has been verified.'
              : '您的付款已提交并等待验证。Your payment is submitted and pending verification. '}
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
}

import * as Yup from 'yup';
import checkoutFormModel from './bookRoomFormModel';
const {
  roomType,
  extraBed,
  findRoommate,
  chineseName,
  name,
  dateOfBirth,
  gender,
  contactNumber,
  passportNumber,
  passportExpiry,
  nationality,
  emergencyContact,
  relationship,
  emergencyContactNumber,
  edenBranch,
  transport,
} = checkoutFormModel.formField;
const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
//eslint-disable-next-line
export default [
  Yup.object().shape({
    [roomType.name]: Yup.string().required(`${roomType.requiredErrorMsg}`),
    [extraBed.name]: Yup.string().required(`${extraBed.requiredErrorMsg}`),
    [findRoommate.name]: Yup.string().required(
      `${findRoommate.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    guests: Yup.array().of(
      Yup.object().shape({
        [chineseName.name]: Yup.string().required(
          `${chineseName.requiredErrorMsg}`
        ),
        [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
        [dateOfBirth.name]: Yup.string().required(
          `${dateOfBirth.requiredErrorMsg}`
        ),
        [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
        [contactNumber.name]: Yup.string().required(
          `${contactNumber.requiredErrorMsg}`
        ),
        [passportNumber.name]: Yup.string().required(
          `${passportNumber.requiredErrorMsg}`
        ),
        [passportExpiry.name]: Yup.string().required(
          `${passportExpiry.requiredErrorMsg}`
        ),
        [nationality.name]: Yup.string().required(
          `${nationality.requiredErrorMsg}`
        ),
        [emergencyContact.name]: Yup.string().required(
          `${emergencyContact.requiredErrorMsg}`
        ),
        [relationship.name]: Yup.string().required(
          `${relationship.requiredErrorMsg}`
        ),
        [emergencyContactNumber.name]: Yup.string().required(
          `${emergencyContactNumber.requiredErrorMsg}`
        ),
        [emergencyContactNumber.name]: Yup.string().required(
          `${emergencyContactNumber.requiredErrorMsg}`
        ),
        [edenBranch.name]: Yup.string().required(
          `${edenBranch.requiredErrorMsg}`
        ),
        [transport.name]: Yup.string().required(
          `${transport.requiredErrorMsg}`
        ),
      })
    ),
  }),

  //   [city.name]: Yup.string().nullable().required(`${city.requiredErrorMsg}`),
  //   [zipcode.name]: Yup.string()
  //     .required(`${zipcode.requiredErrorMsg}`)
  //     .test(
  //       'len',
  //       `${zipcode.invalidErrorMsg}`,
  //       (val) => val && val.length === 5
  //     ),
  //   [country.name]: Yup.string()
  //     .nullable()
  //     .required(`${country.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
  //   [cardNumber.name]: Yup.string()
  //     .required(`${cardNumber.requiredErrorMsg}`)
  //     .matches(visaRegEx, cardNumber.invalidErrorMsg),
  //   [expiryDate.name]: Yup.string()
  //     .nullable()
  //     .required(`${expiryDate.requiredErrorMsg}`)
  //     .test('expDate', expiryDate.invalidErrorMsg, (val) => {
  //       if (val) {
  //         const startDate = new Date();
  //         const endDate = new Date(2050, 12, 31);
  //         if (moment(val, moment.ISO_8601).isValid()) {
  //           return moment(val).isBetween(startDate, endDate);
  //         }
  //         return false;
  //       }
  //       return false;
  //     }),
  //   [cvv.name]: Yup.string()
  //     .required(`${cvv.requiredErrorMsg}`)
  //     .test('len', `${cvv.invalidErrorMsg}`, (val) => val && val.length === 3),
];

import { Box, Container } from '@mui/material';
import BookRoomV2 from 'components/book-room.v2/BookRoomV2';
import MaterialLayout from 'components/book-room.v2/Layout/MaterialLayout';

function App() {
  return (
    <div>
      <MaterialLayout>
        <Container
          sx={{
            height: '100%',
          }}
          component="main"
          maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <BookRoomV2 />
          </Box>
        </Container>
      </MaterialLayout>
    </div>
  );
}

export default App;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Box,TextField } from "@mui/material";

export default function TextCard(props) {
  const [edit,setEdit]=React.useState(false);
  const [cardComment, setCardComment]=React.useState(props.comment);
  const [comment,setComment]=React.useState(props.comment);


  const editCard = (event)=>{
    setEdit(true);
  }
 
   

  const  editComment = async (event)=>{
   await props.editComment(comment);
    setEdit(false);
  }

 
  React.useEffect(() => {
    setCardComment(props.comment)

    }, [props.comment])
  

   
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{ mb: 2, textAlign: "left" }}
          >
            {props.userName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.text}
          </Typography>
          <Typography
            gutterBottom
            variant="h10"
            component="div"
            sx={{ mt: 2, textAlign: "right" }}
          >
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>

      {props.userName ? (
        <CardActionArea>
          <CardContent sx={{ width: "100%" }} onClick={editCard}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mb: 2, textAlign: "left" }}
            >
              Committee Comments
            </Typography>
            {edit?  <TextField
                autoFocus
                margin="normal"
                required
                fullWidth
                id="comment"
                defaultValue={cardComment}
                onChange={(e) => setComment(e.target.value) } //Add your setVariable to this line
                multiline
                rows={4}
              /> :<Typography
              gutterBottom
              component="div"
              sx={{ mt: 2, textAlign: "left", border: 1 }}
            >
              {props.comment}
            </Typography>}
          </CardContent>
          <CardActions fullwidth>
            <Box sx={{ display: "flex", "justify-content": "space-between" }}>
            {!edit? <Button
                size="small"
                color="primary"
                sx={{ textAlign: "right" }}
                onClick={props.resolveTicketOnClick}
              >
                Resolve
              </Button>:<Button
                size="small"
                color="primary"
                sx={{ textAlign: "right" }}
                onClick={editComment}
              >
                Edit Comment
              </Button>}
              
              
            </Box>
          </CardActions>
        </CardActionArea>
      ) : (
        <></>
      )}
    </Card>
  );
}

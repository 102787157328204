import * as pn from "@jeremyling/sg-paynow-qr";

const date = new Date();



  

  export function generatePayNowQR(userId, campfee){
    const [qrString, error] = pn.generateQr({
      amount: campfee, // Optional
      editable: 0, // 1 - Payment Amount is editable, 0 - Payment Amount not editable
      billNumber: "CAMP "+ userId, // Optional, defaults to '***' (User to input reference within payment app)
      merchantName: "Eden Camp", // Optional, defaults to 'NA'
      merchantCountry: "SG", // Optional, defaults to 'SG'
      merchantCity: "Singapore", // Optional, defaults to 'Singapore'
      expiry: new Date(date.setDate(date.getDate() + 1)), // Optional, defaults to 1 day from now
      uen: 'S99SS0100A001', // Required if mobile is null, PayNow registered UEN for businesses
      mobile: undefined, // Required if uen is null, PayNow registered Mobile Number for P2P transfers
    });
    return qrString;
  }
    
 


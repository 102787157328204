import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from 'firebaseFunctions/firebase';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';
import { unpaidBookingsAtom } from 'recoil/booking/atom';
import './CartIconWithBadge.css'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -8,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function CustomizedBadges() {
  const numItemsInCart = useRecoilValue(unpaidBookingsAtom)?.length;

  const userId = useRecoilValue(phoneNumberAtom);
  const [unpaidBookingsList, setUnpaidBookings] =
    useRecoilState(unpaidBookingsAtom);
  useEffect(() => {
    const ref = doc(db, 'account', userId);
    const unSub = onSnapshot(ref, (doc) => {
      const account = doc.data();
      const unpaidBookings = [];
      account.rooms.forEach((room) => {
        if (!room.hasPaid) {
          unpaidBookings.push(room);
        }
      });
      setUnpaidBookings(unpaidBookings);
    });

    // return () => {
    //   unSub();
    // };
  }, []);

  return (
    <StyledBadge  classes={ numItemsInCart>0 ? {badge: 'logo'} : ""}badgeContent={numItemsInCart} color="secondary">
      <ShoppingCartIcon />
    </StyledBadge>
  );
}

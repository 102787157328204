import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { unpaidBookingsAtom } from 'recoil/booking/atom';

export default function Header({ itemCount }) {
  const numItemsInCart = useRecoilValue(unpaidBookingsAtom).length;

  const getItemCount = () => {
    return numItemsInCart < 1 ? 'No' : numItemsInCart;
  };

  return (
    <header>
      <Typography component={'div'} variant="h6">
        Shopping Cart
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item s={4} xs={3}>
          <Breadcrumbs sx={{ padding: '8px' }} aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={'/home'}>
              Home
            </Link>
            <Typography color="text.primary">
              <strong>Cart</strong>
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid display={'flex'} justifyContent={'flex-end'} item xs={6}>
          <span>{getItemCount()} item(s) in your cart</span>
        </Grid>
      </Grid>

      <div
        style={{
          width: '100%',
          borderBottom: '2px solid #7E7E7E',
          margin: 'auto',
        }}></div>
    </header>
  );
}

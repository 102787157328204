import { useAuth } from 'context/AuthContext';
import { logout } from 'helper-functions/authentication';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAdminNumbers, getSpecialCases } from 'firebaseFunctions/firebase';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Container } from '@mui/system';
import ChildrenWorshipModal from 'components/ChildrenWorshipModal';
import HomeModal from 'components/HomeModal';
import HomePageButton from 'components/HomePageButton';

class ButtonDetails {
  constructor(
    content = 'defaultContent',
    color = 'primary',
    clickHandler = () => {},
    disabled = false
  ) {
    this.content = content;
    this.color = color;
    this.clickHandler = clickHandler;
    this.disabled = disabled;
  }
}

export default function Home() {
  const currentUser = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCW, setOpenCW] = useState(false);
  const [isSpecialCase, setIsSpecialCase] = useState(false);

  const navigate = useNavigate();

  const logOutUser = () => {
    logout(navigate);
  };

  useEffect(() => {
    async function checkAdminStatus() {
      const adminNumbers = await getAdminNumbers();
      if (adminNumbers.includes(currentUser.currentUser.phoneNumber)) {
        setIsAdmin(true);
      }
    }

    async function checkSpecialCaseStatus() {
      const specialCases = await getSpecialCases();
      if (specialCases.includes(currentUser.currentUser.phoneNumber)) {
        setIsSpecialCase(true);
      }
    }

    if (currentUser) {
      checkAdminStatus();
      checkSpecialCaseStatus();
    }
  }, [currentUser]);

  const handleCloseModal = (event, data) => {
    setOpen(false);
  };

  const handleOpenCW = (event) => {
    setOpenCW(true);
  };
  const handleCWCloseModal = (event, data) => {
    setOpenCW(false);
  };

  function createNavButtons() {
    const buttonDetailsList = [
      new ButtonDetails(
        'Register for camp / 营会报名',
        'primary',
        () => setOpen(true),
        !(isAdmin || isSpecialCase)      ),
      new ButtonDetails('View booking / 查看预订', 'primary', () =>
        navigate('/view-bookings')
      ),
      new ButtonDetails('Contact us / 联系', 'warning', () =>
        navigate('/contact-us')
      ),
      new ButtonDetails('Log Out / 退出', 'error', logOutUser),
    ];

    return buttonDetailsList.map((buttonDetail) => (
      <React.Fragment key={buttonDetail.content}>
        <Box sx={{ width: '100%', mb: 4 }}>
          <HomePageButton
            color={buttonDetail.color}
            content={buttonDetail.content}
            clickHandler={buttonDetail.clickHandler}
            disabled={buttonDetail.disabled}
          />
        </Box>
      </React.Fragment>
    ));
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {isAdmin ? (
            <Button
              variant="contained"
              sx={{ mb: 10 }}
              onClick={() => navigate('/admin')}>
              Admin Portal
            </Button>
          ) : null}

          <Divider sx={{ mb: 2 }} />
          {createNavButtons()}
        </Box>
        <HomeModal open={open} onCloseModal={handleCloseModal} />
        <ChildrenWorshipModal
          open={openCW}
          onCloseModal={handleCWCloseModal}
        />
      </Container>
    </>
  );
}

import {
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React from "react";
import { InputField } from "../../FormFields";
import FormDivider from "../FormDivider";

const images = [
  {
    url: "/LeMeridianKingRoom.jpeg",
    title: "King",
    width: "50%",
  },
  {
    url: "/LeMeridianTwinRoom.jpeg",
    title: "Twin",
    width: "50%",
  },
  {
    url: "/LeMeridianKingRoom.jpeg",
    title: "Single",
    width: "50%",
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export default function BookRoomFormSelectRoomType(props) {
  const { roomType, extraBed, findRoommate } = props.formField;

  const { clickedImage, setClickedImage } = props;
  const formik = useFormikContext();

  const handleImageClick = (image) => () => {
    setClickedImage(image.title);
    formik.values.roomType = image.title;
    formik.values.findRoommate = false;
    formik.values.extraBed = false;
    formik.setFieldValue("occupants", image.title === "Single" ? 1 : 2);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Select Room
      </Typography>
      <Grid container spacing={3}>
        {images.map((image) => (
          <Grid
            key={image.title}
            sm={4}
            xs={12}
            item
            sx={{
              p: 2,
            }}
          >
            <ImageButton
              onClick={handleImageClick(image)}
              focusRipple
              key={image.title}
              style={{
                width: "100%",
                border:
                  image.title === clickedImage ? "4px solid #1875D1" : "none",
              }}
            >
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop
                style={{
                  opacity: image.title === clickedImage ? "0" : "0.4",
                }}
                className="MuiImageBackdrop-root"
              />
              {!(image.title === clickedImage) && (
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                  >
                    {image.title}
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              )}
            </ImageButton>
          </Grid>
        ))}
        <Grid item xs={12}>
          <InputField
            disabled
            inputProps={{ readOnly: true }}
            name={roomType.name}
            label={roomType.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
        {formik.values.findRoommate || formik.values.roomType === 'Single' ? (
            <FormControl fullWidth disabled>
              <InputLabel id="occupants-label">Number of Occupants</InputLabel>
              <Field
                as={Select}
                labelId="occupants-label"
                id="occupants"
                name="occupants"
                label="Number of Occupants"
                value={1}
                disabled
              >
                <MenuItem value={1}>1</MenuItem>
              </Field>
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <InputLabel id="occupants-label">Number of Occupants</InputLabel>
              <Field
                as={Select}
                labelId="occupants-label"
                id="occupants"
                name="occupants"
                label="Number of Occupants"
                disabled={formik.values.extraBed}
                onChange={formik.handleChange}
                value={formik.values.occupants || ""}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Field>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormDivider content="Additional options" />
          <Field
            as={FormControlLabel}
            type="checkbox"
            name="findRoommate"
            disabled={
              formik.values.occupants > 1 || formik.values.roomType !== "Twin"
            }
            control={<Checkbox name="findRoommate" />}
            label="帮我找室友 / Find me a roommate"
          />
          <Field
            as={FormControlLabel}
            type="checkbox"
            name="extraBed"
            disabled={formik.values.occupants <= 2 || formik.values.findRoommate || formik.values.roomType === 'Single'}
            control={<Checkbox name="extraBed" />}
            label="加床 / Add an extra bed"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

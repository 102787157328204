import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Divider, Typography} from '@mui/material';
import { generatePayNowQR } from 'paynowQRFunctions/payNowExample';
import { Button } from '@mui/material';
import paynowLogo from 'paynowLogo.png'
import { payRoomsInCart } from 'firebaseFunctions/firebase';
import ManIcon from '@mui/icons-material/Man';
import BoyIcon from '@mui/icons-material/Boy';
import CribIcon from '@mui/icons-material/Crib';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { useNavigate } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80vh',
  width: '75vw',
  textAlign: 'center',
  bgcolor: 'background.paper',
  overflow: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function HomeModal(props) {
    const navigate = useNavigate();

 
    //props.editRoomObject is passed here
  const [open, setOpen] = React.useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = (event) => {
  
    let data = { name: 'example', type: 'closed from child' };
    props.onCloseModal(event, data);
  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
            {/* <Typography variant="h5">会费 / Pricing</Typography>
            <Typography sx={{mt:2}} ><ManIcon/>成人 / Adult : $380</Typography>
            <Divider/>
            <Typography ><BoyIcon/>儿童 （成人床） /  Child (adult bed) : $240</Typography>
            <Divider/>
            <Typography ><BoyIcon/>儿童（加床） /Child (extra bed): $150</Typography>
            <Divider/>
            <Typography ><CribIcon/>儿童（0-4岁）Children (0-4 years): F.O.C</Typography>
            <Divider/>
            <Typography ><DirectionsBusIcon/>巴士 / Coach Ticket:$55</Typography>
            <Divider/> */}
            <Typography sx={{mt:2}} variant="h5"> Max 4 Per Room</Typography>
            <Divider sx={{mb:2}}/>
            <Typography>1 Adult in a single room: Cost $600</Typography>
            <Typography>1 Adult (Find Roommate): Cost $380</Typography>
            <Typography>1 Adult + Infant: Cost $600 </Typography>
            <Typography>1 Adult and 1 Child: Cost $750 </Typography>
<Typography>2 Adults: Cost $760 </Typography>
            <Typography>1 Adult + 2 Child(extra bed) $1010 </Typography>
            <Typography>1 Adult + 2 Child(w/o bed) $900 </Typography>
            <Typography>2 Adult + 1 Child(extra bed): Cost $1020</Typography>
            <Typography>2 Adult + 1 Child(w/o bed): Cost $910</Typography>
            <Typography>3 Adult (extra bed): Cost $1020</Typography>
            <Typography>3 Adult + 1 Child(extra bed): Cost $1170</Typography>
            <Typography>2 Adult + 2 Child(extra bed): Cost $1170</Typography>
            <Typography>2 Adult + 2 Child(w/o bed): Cost $1060</Typography>
            <Divider sx={{mb:2}}/>
            <Typography >如果您的房间要求不在上面列出，请联系委员特殊报名。</Typography>
            <Typography >If your room requirements are not listed above, please approach the committee for special registration.</Typography>
            
            
           
            <Button sx={{mt:2}} variant='contained' onClick={() =>
        navigate('/book-room')}>Proceed</Button>


        </Box>
       
      </Modal>
    </div>
  );
}

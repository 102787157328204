import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const testForNumbers = /^\d+$/;

export default function SignUpForm() {
  const validationSchema = yup.object().shape({
    firstName: yup.string('Enter your first name').trim().required('Required'),
    lastName: yup.string('Enter your last name').trim().required('Required'),
    mobileNumber: yup
      .string('Enter a valid number')
      .trim()
      .matches(testForNumbers, 'Must only consist numbers 0 - 9')
      .min(8, 'Phone number should be 8 digits or more')
      .required('Required'),
    email: yup.string('Enter your email').trim().email('Enter a valid email'),
    password: yup
      .string('Enter your password')
      .trim()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Required'),
    retypePassword: yup
      .string('Enter your password')
      .trim()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      email: '',
      password: '',
      retypePassword: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }, 4000);
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box sx={{ mt: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First name*"
                    type="text"
                    value={formik.values.firstName}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last name*"
                    type="text"
                    value={formik.values.lastName}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    id="mobileNumber"
                    name="mobileNumber"
                    label="Mobile no.*"
                    value={formik.values.mobileNumber}
                    type="text"
                    error={
                      formik.touched.mobileNumber &&
                      Boolean(formik.errors.mobileNumber)
                    }
                    helperText={
                      formik.touched.mobileNumber && formik.errors.mobileNumber
                    }></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    id="email"
                    value={formik.values.email}
                    name="email"
                    label="Email"
                    type="text"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={
                      formik.touched.email && formik.errors.email
                    }></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    fullWidth
                    onBlur={formik.handleBlur}
                    id="password"
                    name="password"
                    label="Password*"
                    type="password"
                    value={formik.values.password}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    id="retypePassword"
                    name="retypePassword"
                    label="Confirm password*"
                    type="password"
                    value={formik.values.retypePassword}
                    error={
                      formik.touched.retypePassword &&
                      Boolean(formik.errors.retypePassword)
                    }
                    helperText={
                      formik.touched.retypePassword &&
                      formik.errors.retypePassword
                    }></TextField>
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="acknowledgeTerms" color="primary" />
                    }
                    label="I agree to Eden BP terms of use of this application."
                  />
                </Grid> */}
              </Grid>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/sign-in" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

import { Box, Container, Divider, Typography } from '@mui/material';
import MediaCard from 'components/view-bookings/MediaCard';
import { getPaidBookingsViaPhone } from 'firebaseFunctions/firebase';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';

class CardDetails {
  constructor(
    title = 'defaultContent',

    price = '$' + 0,
    guests = ['hi', 'hello'],
    verifiedPayment = false,
    roomType = 'king'
  ) {
    this.title = title;
    this.price = price;
    this.guests = guests;
    this.verifiedPayment = verifiedPayment;
    this.roomType = roomType;
  }
}

function createMediaCards(bookings) {
  let cardDetailsList = [];
  bookings.forEach((room, index) => {
    const totalCost = room.roomCost + room.transportCost;
    const card = new CardDetails(
      'Room ' + index + 1,
      totalCost,
      room.guests,
      room.verifiedPayment,
      room.roomType
    );
    cardDetailsList.push(card);
  });

  return cardDetailsList.map((cardDetail) => (
    <React.Fragment key={cardDetail.title}>
      <Box sx={{ width: '100%', mb: 4 }}>
        <MediaCard
          title={cardDetail.title}
          price={cardDetail.price}
          guests={cardDetail.guests}
          verifiedPayment={cardDetail.verifiedPayment}
          roomType={cardDetail.roomType}
        />
      </Box>
    </React.Fragment>
  ));
}

function ViewBookings() {
  const [booking, setBooking] = React.useState([]);
  const userId = useRecoilValue(phoneNumberAtom);

  React.useEffect(() => {
    getPaidBookingsViaPhone(userId).then((res) => {
      setBooking(res);
    });
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography
          sx={{ mb: 4 }}
          variant="h4"
          color="text.primary"
          align="left">
          View Bookings
          <Divider />
        </Typography>

        {createMediaCards(booking)}
      </Box>
    </Container>
  );
}

export default ViewBookings;

import { Box } from '@mui/material';
import { Container } from '@mui/system';
import CartBody from 'components/cart/CartBody';
import CartHeader from 'components/cart/CartHeader';
import CartSummary from 'components/cart/CartSummary';

// eslint-disable-next-line import/no-anonymous-default-export
export default function Cart() {
  return (
    <Container
      sx={{
        height: '100%',
      }}
      component="main"
      maxWidth="md">
      <CartHeader />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <CartBody />
        <CartSummary />
      </Box>
    </Container>
  );
}

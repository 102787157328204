import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Typography} from '@mui/material';
import { generatePayNowQR } from 'paynowQRFunctions/payNowExample';
import { Button } from '@mui/material';
import paynowLogo from 'paynowLogo.png'
import { payRoomsInCart } from 'firebaseFunctions/firebase';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  bgcolor: 'background.paper',
  overflow: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function QRModal(props) {
       
 
    //props.editRoomObject is passed here
  const [open, setOpen] = React.useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = (event) => {
  
  
    let data = { name: 'example', type: 'closed from child' };
    props.onCloseModal(event, data);
  };

  const payForRooms = async (event) =>{
   
    await payRoomsInCart(props.userId);
    window.location.reload(false);
  }
  
  const qRstring = generatePayNowQR(props.userId, props.amount)

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}><QRCode size={300} value={qRstring} logoWidth ={120} logoHeight={60} logoImage={paynowLogo} />
        <Box><Typography>请扫描Paynow二维码</Typography><Typography>通过 PayNow 付款后点击下面的按钮 </Typography><Typography>Please Scan the Paynow QR Code</Typography><Typography>Click the button below only AFTER you have made payment via PayNow </Typography><Button
            sx={{
              mt: 2,
            }}
            fullWidth
            variant="contained"
            onClick={payForRooms}>
            I have paid
          </Button></Box>
        </Box>
       
      </Modal>
    </div>
  );
}

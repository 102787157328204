const COST_PER_ADULT_ALONE = 600;
const COST_PER_ADULT = 380;
const COST_PER_CHILD = 260;
const COST_PER_CHILD_ON_EXTRA_BED = 150;
const COST_PER_COACH_ONE_WAY_TICKET = 40;
const COST_PER_COACH_ROUND_TRIP_TICKET = 65;

export default {
  COST_PER_ADULT,
  COST_PER_ADULT_ALONE,
  COST_PER_CHILD,
  COST_PER_CHILD_ON_EXTRA_BED,
  COST_PER_COACH_ONE_WAY_TICKET,
  COST_PER_COACH_ROUND_TRIP_TICKET
};

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  Timestamp,
  where,
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyC8bJUgDk5I-PHuT-Ob784A9Wbet8XmmUo',
  authDomain: 'edencamp-2022.firebaseapp.com',
  databaseURL:
    'https://edencamp-2022-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'edencamp-2022',
  storageBucket: 'edencamp-2022.appspot.com',
  messagingSenderId: '188710728944',
  appId: '1:188710728944:web:8bfd4f05d8397856647e35',
  measurementId: 'G-CFYDBWD17P',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore();
export const dbref = query(collectionGroup(db, 'account'));

export function useAuth() {
  //
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) =>
      setCurrentUser(user)
    );
    return unSubscribe;
  }, []);
  return currentUser;
}

export async function queryFirebase(database, condition) {
  const dbref = query(collectionGroup(db, database));
  const querySnapshot = await getDocs(dbref);
  let results = [];
  querySnapshot.forEach((doc) => {
    let docObject = doc.data();
    docObject.id = doc.id;
    results.push(docObject);
  });
  return results;
}

export async function queryFullObject(database, condition) {
  const dbref = query(collection(db, database));
  const querySnapshot = await getDocs(dbref);
  let results = [];
  querySnapshot.forEach((doc) => {
    let docObject = doc.data();
    docObject.id = doc.id;
    results.push(docObject);
  });
  return results;
}

export async function getPaidBookingsViaPhone(phoneNumber) {
  try {
    const bookingsRef = collection(db, 'account');
    const getBookingsQuery = query(
      bookingsRef,
      where('mobile', '==', phoneNumber)
    );

    const response = await getDocs(getBookingsQuery);
    let paidBookings = [];
    response.forEach((doc) => {
      const account = doc.data();
      account.rooms.forEach((room) => {
        if (room.hasPaid) {
          paidBookings.push(room);
        }
      });
    });

    return paidBookings;
  } catch (error) {
    console.error(error);
  }
}

export async function getSingleAccount(userId) {
  const docRef = doc(db, 'account', userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const result = docSnap.data();
    return result;
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');
    return false;
  }
}

export async function getRoomViaRoomId(userId,roomId) {
  const docRef = doc(db, 'account', userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const booking = docSnap.data();
    booking.rooms.forEach(room => {
      if(room.bookingId==="roomId"){
        return room;
      }
    });
  

    
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');
    return false;
  }
}

export async function addUsers(user) {
  //check if user exists
  const userInFirebase = await getSingleAccount(user.phoneNumber);
  //if yes, ignore

  if (userInFirebase) {
    console.info('user already exists, skipping creation');
  } else {
    //if no, create user with empty array
    console.info('user does not exist. creating user');
    let account = {
      mobile: user.phoneNumber,
      rooms: [],
    };

    const response = await setDoc(
      doc(db, 'account', user.phoneNumber),
      account,
      {
        merge: true,
      }
    ).then(() => {
      console.info('user added');
    });
  }
  //localStorage.setItem("user", user.phoneNumber);
}

export async function editUser(account) {
  const response = await setDoc(doc(db, 'account', account.mobile), account, {
    merge: true,
  }).then(() => {
    console.log('Account updated');
  });
  console.log(response);
}

export async function payRoomsInCart(userId) {
  const userInFirebase = await getSingleAccount(userId);

  userInFirebase.rooms.forEach((room) => {
    room.hasPaid = true;
  });

  const response = await setDoc(
    doc(db, 'account', userId),
    userInFirebase,
    {
      merge: true,
    }
  ).then(() => {
    console.log('Rooms Paid');
  });
  console.log(response);
}

export async function createTicket(ticket) {
  ticket.submitted = Timestamp.fromDate(new Date());

  const response = await addDoc(collection(db, 'tickets'), ticket)
    .then(() => {
      console.log('ticket submitted');
    })
    .catch((error) => {
      console.error(error);
    });
  console.log(response);
}

export async function createCWApplication(application) {
  application.submitted = Timestamp.fromDate(new Date());

  const response = await addDoc(collection(db, 'cwresponses'), application)
    .then(() => {
      console.log('application submitted');
    })
    .catch((error) => {
      console.error(error);
    });
  console.log(response);
}

//function to get tickets of individual user.
export async function getUserTicket(userId) {
  try {
    let tickets = [];
    const dbRef = collection(db, 'tickets');
    const getTicketsQuery = query(dbRef, where('mobile', '==', userId));

    const response = await getDocs(getTicketsQuery);
    response.forEach((doc) => {
      tickets.push(doc.data());
    });
    return tickets;
  } catch (error) {
    console.error(error);
  }
}
export async function getTicket(ticketId) {
  console.log('passed ticketId', ticketId);
  const docRef = doc(db, 'tickets', ticketId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const result = docSnap.data();
    console.log('Document data:', result);
    return result;
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');
    return false;
  }
}

export async function resolveTicket(ticketId) {
  const serverTicket = await getTicket(ticketId);
  serverTicket.isResolved = !serverTicket.isResolved;
  const response = await setDoc(doc(db, 'tickets', ticketId), serverTicket, {
    merge: true,
  }).then(() => {
    console.log('toggled ticket');
  });
  console.log(response);
}

export async function editTicketComment(ticketId, comment) {
  const serverTicket = await getTicket(ticketId);
  serverTicket.comment = comment;
  const response = await setDoc(doc(db, 'tickets', ticketId), serverTicket, {
    merge: true,
  }).then(() => {
    console.log('added comment');
  });
  console.log(response);
}
export async function editRoomComment(userId,roomId,comment) {
  const userInFirebase = await getSingleAccount(userId);

  userInFirebase.rooms.forEach((room) => {
    if(room.bookingId===roomId){
      room.comment = comment;
    }
  });

  const response = await setDoc(
    doc(db, 'account', userId),
    userInFirebase,
    {
      merge: true,
    }
  ).then(() => {
    console.log('Verified Payment');
  });
  console.log(response);
}

export async function verifyPayment(userId, roomId, name) {
  const userInFirebase = await getSingleAccount(userId);

  userInFirebase.rooms.forEach((room) => {
    if (room.bookingId === roomId) {
      room.verifiedPayment = true;
      room.verifiedBy = name; // Assigning the name to verifiedBy
      room.hasPaid=true;
    }
  });

  const response = await setDoc(
    doc(db, 'account', userId),
    userInFirebase,
    {
      merge: true,
    }
  ).then(() => {
    console.log('Verified Payment');
  });

  console.log(response);
}

export async function editRoomCostVerified(userId, roomId, newRoomCost, newTransportCost, verifiedBy) {
  const userInFirebase = await getSingleAccount(userId);

  userInFirebase.rooms.forEach((room) => {
    if(room.bookingId === roomId) {
      room.roomCost = newRoomCost;
      room.transportCost = newTransportCost;
      room.editedBy = verifiedBy;
    }
  });

  await setDoc(
    doc(db, 'account', userId),
    userInFirebase,
    { merge: true }
  );
  console.log('Room costs updated and verified by', verifiedBy);
}


//function to pull list of admins
export async function getAdminNumbers() {
  try {
    const mainListDocRef = doc(db, 'adminList', 'mainList');
    const docSnapshot = await getDoc(mainListDocRef);

    if (docSnapshot.exists()) {
      const adminData = docSnapshot.data();
      if (adminData.adminNumbers && Array.isArray(adminData.adminNumbers)) {
        return adminData.adminNumbers;
      } else {
        console.error("Admin numbers array not found or not an array.");
        return [];
      }
    } else {
      console.error("Main list document does not exist.");
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getSpecialCases() {
  try {
    const mainListDocRef = doc(db, 'specialCases', 'specialList');
    const docSnapshot = await getDoc(mainListDocRef);

    if (docSnapshot.exists()) {
      const adminData = docSnapshot.data();
      if (adminData.specialNumbers && Array.isArray(adminData.specialNumbers)) {
        return adminData.specialNumbers;
      } else {
        console.error("Admin numbers array not found or not an array.");
        return [];
      }
    } else {
      console.error("Main list document does not exist.");
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}


//function to pull list of verifiers
export async function getVerifyNumbers() {
  try {
    const mainListDocRef = doc(db, 'adminList', 'verifyList');
    const docSnapshot = await getDoc(mainListDocRef);

    if (docSnapshot.exists()) {
      const adminData = docSnapshot.data();
      if (adminData.adminNumbers && Array.isArray(adminData.adminNumbers)) {
        return adminData.adminNumbers;
      } else {
        console.error("Admin numbers array not found or not an array.");
        return [];
      }
    } else {
      console.error("Main list document does not exist.");
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function injectRoomsIntoAccount(userId, roomsArray, mode = 'append') {
  const userInFirebase = await getSingleAccount(userId);
  
  if (userInFirebase) {
    let updatedRooms;

    switch (mode) {
      case 'append':
        // Append new rooms to existing ones
        updatedRooms = [...userInFirebase.rooms, ...roomsArray];
        break;
      case 'replace':
        // Replace existing rooms with new ones
        updatedRooms = [...roomsArray];
        break;
      case 'merge':
        // Merge new rooms, updating existing ones if bookingId matches
        updatedRooms = userInFirebase.rooms.map(room => {
          const updatedRoom = roomsArray.find(newRoom => newRoom.bookingId === room.bookingId);
          return updatedRoom ? { ...room, ...updatedRoom } : room;
        });
        // Add new rooms that don't exist in the current array
        roomsArray.forEach(newRoom => {
          if (!updatedRooms.some(room => room.bookingId === newRoom.bookingId)) {
            updatedRooms.push(newRoom);
          }
        });
        break;
      default:
        throw new Error('Invalid mode specified');
    }

    userInFirebase.rooms = updatedRooms;

    const response = await setDoc(
      doc(db, 'account', userId),
      userInFirebase,
      {
        merge: true,
      }
    );
    console.log(`Rooms ${mode}d successfully`);
    return true;
  } else {
    console.log('User not found');
    return false;
  }
}

export async function mergeRooms(primaryRoomId, secondaryRoomId) {
  try {
    const accountsRef = collection(db, 'account');
    const accountsSnapshot = await getDocs(accountsRef);

    if (accountsSnapshot.empty) {
      throw new Error('No accounts found in the database');
    }

    let primaryAccount, secondaryAccount, primaryRoom, secondaryRoom;

    accountsSnapshot.forEach((doc) => {
      const account = doc.data();
      if (!account.rooms || !Array.isArray(account.rooms)) {
        console.warn(`Account ${doc.id} has no rooms or rooms is not an array`);
        return;
      }
      
      account.rooms.forEach((room) => {
        if (room.bookingId === primaryRoomId) {
          primaryAccount = { id: doc.id, ...account };
          primaryRoom = room;
        }
        if (room.bookingId === secondaryRoomId) {
          secondaryAccount = { id: doc.id, ...account };
          secondaryRoom = room;
        }
      });
    });

    if (!primaryRoom || !secondaryRoom) {
      throw new Error('One or both rooms not found');
    }

    // Merge the rooms
   // Merge the rooms
   const mergedRoom = {
    ...primaryRoom,
    occupants: primaryRoom.guests.length + secondaryRoom.guests.length,
    roomCost: primaryRoom.roomCost + secondaryRoom.roomCost,
    transportCost: primaryRoom.transportCost + secondaryRoom.transportCost,
    guests: [...primaryRoom.guests, ...secondaryRoom.guests],
    findRoommate: false,
    // Merge comments
    comment: [primaryRoom.comment, secondaryRoom.comment]
      .filter(Boolean)  // Remove any undefined or empty comments
      .join(" |MERGE| ")
  };

    if (primaryAccount.id === secondaryAccount.id) {
      // Both rooms are in the same account
      const updatedRooms = primaryAccount.rooms.map(room =>
        room.bookingId === primaryRoomId ? mergedRoom :
        room.bookingId === secondaryRoomId ? null : room
      ).filter(room => room !== null);

      await updateDoc(doc(db, 'account', primaryAccount.id), {
        rooms: updatedRooms
      });
    } else {
      // Rooms are in different accounts
      const updatedPrimaryRooms = primaryAccount.rooms.map(room =>
        room.bookingId === primaryRoomId ? mergedRoom : room
      );

      await updateDoc(doc(db, 'account', primaryAccount.id), {
        rooms: updatedPrimaryRooms
      });

      const updatedSecondaryRooms = secondaryAccount.rooms.filter(room =>
        room.bookingId !== secondaryRoomId
      );

      await updateDoc(doc(db, 'account', secondaryAccount.id), {
        rooms: updatedSecondaryRooms
      });
    }

    // Create a merged account document with only the merged room
    const mergedAccountDoc = {
      id: secondaryAccount.id,
      mobile: secondaryAccount.mobile,
      rooms: [mergedRoom]
    };

    await setDoc(doc(db, 'mergedAccounts', secondaryAccount.id), mergedAccountDoc);

    return { success: true, mergedRoom };
  } catch (error) {
    console.error('Error merging rooms:', error);
    return { success: false, error: error.message };
  }
}



export default app;

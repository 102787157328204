import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Field, useField, useFormikContext } from 'formik';
import { at } from 'lodash';
import PropTypes from 'prop-types';

function SelectField(props) {
  const { label, data, customFields, ...rest } = props;

  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const formik = useFormikContext();

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const getFields = () => {
    //Guard cause
    if (customFields) return customFields;
    return data?.map((datum, idx) => {
      const { value, label } = datum;

      const lowerCaseValue = value?.toString().toLowerCase();
      return (
        <MenuItem
          key={lowerCaseValue + idx}
          variant="body1"
          value={lowerCaseValue}>
          {label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl {...rest} error={isError} sx={{ width: '100%' }}>
      <InputLabel id="edenBranch">{label}</InputLabel>
      <Field
        labelId={'edenBranch'}
        name={props.name}
        label={label}
        {...rest}
        as={Select}>
        {getFields()}
      </Field>

      {/* <Field
        label={label}
        labelId="edenBranch"
        type="text"
        component={Select}
        variant="outlined"
        children={getFields()}></Field> */}
      {/* {_renderHelperText()} */}
      {/* <ErrorMessage name={props.name} /> */}
    </FormControl>
  );

  // <FormControl {...rest} error={isError}>
  //   <InputLabel>{label}</InputLabel>
  //   <Select {...field} value={selectedValue ? selectedValue : ''}>
  //     {data.map((item, index) => (
  //       <MenuItem key={index} value={item.value}>
  //         {item.label}
  //       </MenuItem>
  //     ))}
  //   </Select>
  //   {_renderHelperText()}
  // </FormControl>
  // );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;

import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Link,
    Typography
  } from "@mui/material"
  
  const MallImageList = () => {
    return (
      <ImageList variant="masonry" cols={2} gap={8}>
        {mallPhotoData.map(item => (
          <ImageListItem key={item.img}>
            <img
              srcSet={item.img}
              src={item.img}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              position="top"
              title={item.title}
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                  "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)"
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    )
  }
  
  const mallPhotoData = [
    {
      img: 'https://i.ibb.co/vxJ0NM5/adventure-park.png',
      title: "Adventure Park"
    },
    {
      img: 'https://i.ibb.co/Yh7BsPN/badminton-court.png',
      title: "Badminton Courts"
    },
    {
      img: 'https://i.ibb.co/GtH1bnz/futsal-court.png',
      title: "Futsal Courts"
    },
    {
      img: 'https://i.ibb.co/Yy8dck1/ice-skating-rink.png',
      title: "Ice Skating Rink"
    },
    {
      img: 'https://i.ibb.co/G95t7sB/bowling.jpg',
      title: "Bowling Alley"
    }
  ]
  
  const HotelImageList = () => {
    return (
      <ImageList variant="masonry" cols={2} gap={8}>
        {hotelPhotoData.map(item => (
          <ImageListItem key={item.img}>
            <img
              srcSet={item.img}
              src={item.img}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              position="top"
              title={item.title}
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                  "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)"
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    )
  }
  
  const hotelPhotoData = [
    {
      img: 'https://i.ibb.co/Q8Jj35m/conference-room.png',
      title: "Ballroom"
    },
    {
      img: 'https://i.ibb.co/pJ8N2tz/dining-hall.png',
      title: "Dining Hall"
    },
    {
      img: 'https://i.ibb.co/T0DkhjC/gym.png',
      title: "Gym"
    },
    {
      img: 'https://i.ibb.co/591WTNj/swimming-pool.png',
      title: "Swimming Pool"
    }
  ]
  
  export const LocationDetails = (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.4619722046928!2d101.7097964760795!3d2.969231554229998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdca0eacdb65ff%3A0xedd359875ada56de!2sLe%20M%C3%A9ridien%20Putrajaya!5e0!3m2!1sen!2ssg!4v1714402973344!5m2!1sen!2ssg"
        width="100%"
        height="200"
        loading="lazy"
        title="hotel-map"
        style={{ borderRadius: 5 }}
      ></iframe>
      <Link
        href="https://www.marriott.com/en-us/hotels/kuldm-le-meridien-putrajaya/overview/"
        variant="h6"
        underline="none"
      >
        Le Meridian Putrajaya
      </Link>
      <Typography variant="body1">
        Located next to the largest shopping mall in Malaysia, there are many
        facilities to enjoy:
      </Typography>
  
      <HotelImageList />
      <Link
        href="https://www.ioicitymall.com.my/home/"
        variant="h6"
        underline="none"
      >
        IOI mall
      </Link>
      <MallImageList />
    </>
  )
  
import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { generatePayNowQR } from 'paynowQRFunctions/payNowExample';
import { Button, TextField,ButtonGroup } from '@mui/material';
import paynowLogo from 'paynowLogo.png'
import { payRoomsInCart } from 'firebaseFunctions/firebase';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 
  bgcolor: 'background.paper',
  overflow: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function QRGenerator() {
       
 
    //props.editRoomObject is passed here
  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const [amount, setAmount] = React.useState();
  const [qRstring, setqRstring] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = (event) => {
    setOpen(false);
  };

  const handleClick = ()=>{
    if(amount>0){
        setqRstring(generatePayNowQR(userId, amount)) 
    setOpen(true);
    }
    
  }

  

  
  

  return (
    
    <div>
        
        <TextField
                        autoFocus
                        margin="normal"
                        required
                        fullWidth
                        name="userId"
                        label="Mobile Number"
                        type="userId"
                        id="userId"
                        defaultValue="+65"
                        onChange={(event)=>{                      
                            setUserId(event.target.value);
                          }}

                    />
                    

<TextField
                        autoFocus
                        margin="normal"
                        required
                        fullWidth
                        name="amount"
                        label="Amount"
                        type="amount"
                        id="amount"
                        defaultValue={0}
                        onChange={(event)=>{                      
                            setAmount(event.target.value);
                          }}

                    />


       
        <Button variant='contained' onClick={handleClick}>Generate</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}><QRCode size={300} value={qRstring} logoWidth ={120} logoHeight={60} logoImage={paynowLogo} />
        <p>Please Scan the Paynow QR Code </p> 
        <p>Click the button below only AFTER you have made payment via PayNow</p>
        </Box>
       
      </Modal>
      
    </div>
  );
}

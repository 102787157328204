import WithNav from "components/WithNav";
import WithoutNav from "components/WithoutNav";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "utils/PrivateRoute";
import Admin from "views/Admin";
import BookRoom from "views/BookRoom";
import BookRoomV2 from "views/BookRoom.v2";
import Cart from "views/Cart";
import ComingSoon from "views/ComingSoon";
import ContactUs from "views/ContactUs";
import Home from "views/Home";
import SignIn from "views/SignIn";
import SignUp from "views/SignUp";
import ViewBookings from "views/ViewBookings";
import AdminRoutes from "utils/AdminRoute";
import ChildrenWorship from "views/ChildrenWorship";
export default function routes() {
  return (
    <Routes>
      <Route exact path="/" element={<ComingSoon />} />
      <Route element={<WithoutNav />}>
        <Route path="/sign-in" name="SignInPage" element={<SignIn />} />
        <Route path={"/coming-soon"} element={<ComingSoon />}></Route>
      </Route>

      <Route element={<WithNav />}>
        <Route element={<AdminRoutes />}>
          <Route path="/admin" name="Admin" element={<Admin />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path={"/"} element={<ComingSoon />}></Route>
          <Route path="/home" name="HomePage" element={<Home />}></Route>
          <Route path="/cart" name="Cart" element={<Cart />}></Route>
          <Route
            path="/contact-us"
            name="ContactUs"
            element={<ContactUs />}
          ></Route>
          <Route
            path="/children-worship"
            name="ChildrenWorship"
            element={<ChildrenWorship />}
          ></Route>
          <Route
            path="/view-bookings"
            name="ViewBookings"
            element={<ViewBookings />}
          ></Route>

          <Route
            path="/book-room-v1"
            name="BookRoomPage"
            element={<BookRoom />}
          />
          <Route
            path="/book-room"
            name="BookRoomPage2"
            element={<BookRoomV2 />}
          />

          <Route path="/sign-up" name="SignUpPage" element={<SignUp />} />
        </Route>
      </Route>
    </Routes>
  );
}

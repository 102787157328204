import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "context/AuthContext";
import { getAdminNumbers } from "firebaseFunctions/firebase"; // Import the function to get admin numbers

const AdminRoutes = () => {
  const currentUser = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    async function checkAdminStatus() {
      if (currentUser?.currentUser?.phoneNumber) { // Ensure currentUser and phoneNumber are loaded
        const adminNumbers = await getAdminNumbers();
        if (adminNumbers.includes(currentUser.currentUser.phoneNumber)) {
  
          setIsAdmin(true);
        }
        setLoading(false); // Set loading to false after check
      }
    }

    checkAdminStatus();
  }, [currentUser]);

  const location = useLocation();
 
  if (loading) return null; // or a loading spinner, etc...

  return isAdmin ? (
    <Outlet />
  ) : (
    <Navigate to="/home" />
  );
};

export default AdminRoutes;

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import EditBookingButtons from "./EditBookingButtons";
import { verifyPayment } from "firebaseFunctions/firebase";
import { editRoomComment } from "firebaseFunctions/firebase";
import { useAuth } from "context/AuthContext";
import { editRoomCostVerified, getVerifyNumbers } from "firebaseFunctions/firebase";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '60vw',
  bgcolor: "background.paper",
  overflow: "auto",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditBookingModal(props) {
  const currentUser = useAuth();
  const [isVerified, setIsVerified] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchVerifierNumbers = async () => {
      const verifierNumbers = await getVerifyNumbers();
      setIsVerified(verifierNumbers.includes(currentUser.currentUser.phoneNumber));
      setLoading(false);
    };
    fetchVerifierNumbers();
  }, [currentUser.currentUser.phoneNumber]);

  const handleClose = (event) => {
    let data = { name: "example", type: "closed from child" };
    props.onCloseModal(event, data);
  };

  const verifyRoomPayment = () => {
    let name = '';
    switch (currentUser.currentUser.phoneNumber) {
      case "+6592993466":
        name = "Edward";
        break;
      case "+6598567184":
        name = "CF";
        break;
      case "+6598310235":
        name = "YX";
        break;
      default:
        name = ""; // Default case, if the phone number doesn't match any of the cases
        break;
    }
    verifyPayment(props.userId, props.room.bookingId, name);
  };

  const editComment = (editedComment) => {
    if (editedComment) {
      editedComment = `${editedComment} ${moment().format('YYYY-MM-DD HH:mm:SS')}`;
      editRoomComment(props.userId, props.room.bookingId, editedComment);
    }
    props.onCloseModal();
  };

  const editRoomCost = (newRoomCost, newTransportCost) => {
    let name = '';
    switch (currentUser.currentUser.phoneNumber) {
      case "+6592993466":
        name = "Edward";
        break;
      case "+6598567184":
        name = "CF";
        break;
      case "+6598310235":
        name = "YX";
        break;
      default:
        name = ""; // Default case, if the phone number doesn't match any of the cases
        break;
    }
    editRoomCostVerified(props.userId, props.room.bookingId, Number(newRoomCost), Number(newTransportCost), name);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <EditBookingButtons 
          verifyRoomPayment={verifyRoomPayment} 
          comment={props.room.comment} 
          editComment={editComment} 
          editRoomCost={editRoomCost}
          room={props.room}
          isVerified={isVerified} 
          loading={loading}
        />
      </Box>
    </Modal>
  );
}

import React, { useState,useEffect } from "react";
import TextField from "@mui/material/TextField";
import {Divider, FormControl, Typography,FormControlLabel,Checkbox} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Container,
  CssBaseline,
  InputAdornment,
  Button,
} from "@mui/material";
import { createTicket, getUserTicket } from "firebaseFunctions/firebase";
import TextCard from "components/TicketCard";
import { useRecoilValue } from "recoil";
import { phoneNumberAtom } from "recoil/account/atom";
import AddOccupant from "components/book-room.v2/BookRoomV2/Forms/BookRoomFormAddOccupant";
import checkoutFormModel from 'components/book-room.v2/BookRoomV2/FormModel/bookRoomFormModel';


const theme = createTheme();

  


export default function MasterForm() {
    const { formId, formField } = checkoutFormModel;

    return(
        <>
       
        </>
    )
}

import { Button, Grid, Typography } from '@mui/material';
import QRModal from 'components/QRModal';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';
import { unpaidBookingsAtom } from 'recoil/booking/atom';

export default function CartSummary() {
  const [open, setOpen] = useState(false);

  const [totalCost, setTotalCost] = useState(0);

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleCloseModal = (event, data) => {
    setOpen(false);
  };

  const unpaidBookings = useRecoilValue(unpaidBookingsAtom);

  const userId = useRecoilValue(phoneNumberAtom);

  useEffect(() => {
    let totalCostCalc = 0;
    unpaidBookings.forEach((room) => {
      totalCostCalc = totalCostCalc + room.roomCost + room.transportCost;
    });
    setTotalCost(totalCostCalc);
  }, [unpaidBookings]);

  return (
    <>
      <Grid
        sx={{
          mt: 2,
        }}
        container
        justifyContent={'end'}>
        <Grid item xs={4}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Total: {`SGD ${totalCost}`}
          </Typography>
          {unpaidBookings?.length > 0 ? (
            <Button
              sx={{
                mt: 2,
              }}
              fullWidth
              variant="contained"
              onClick={handleClick}>
              Pay
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <QRModal
        open={open}
        onCloseModal={handleCloseModal}
        amount={totalCost}
        userId={userId}></QRModal>
    </>
  );
}

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Container,
  CssBaseline,
  InputAdornment,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { createCWApplication } from "firebaseFunctions/firebase";
import TextCard from "components/TicketCard";
import { useRecoilValue } from "recoil";
import { phoneNumberAtom } from "recoil/account/atom";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function ChildrenWorship() {
  
  const [checked, setChecked] = React.useState(true);
  const [ageChecked, setAgeChecked] = React.useState(true);
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgeChecked(event.target.checked);
  };

 

  const onSubmitHandle = (event) => {
    event.preventDefault();
    
    let applicationObject = {
      mobile: "+65" + event.target.mobile.value,
      name: event.target.name.value,
      kidsExperience: event.target.experience.value,
      isAdult: true,
      isCampExempt:true,
      
    };
    createCWApplication(applicationObject);
    navigate('/home');
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />

         
            <Box
              component="form"
              onSubmit={onSubmitHandle}
              noValidate
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                autoFocus
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="mobile"
                label="Mobile"
                name="mobile"
                // error ={phoneError ? true : false }
                // helperText={phoneError ?  "Invalid Phone" :""}
                inputProps={{ maxLength: 8 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+65</InputAdornment>
                  ),
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
              <Box fullwidth sx={{textAlign:'left',mb:4}}><FormGroup sx={{}}>
                <FormControlLabel
                  control={<Checkbox  />}
                  label="我已经 18 岁以上 / I am over 18 years old"
                  onChange={handleAgeChange}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="我明白通过参加这项服侍，我将无法在营会期间参加聚会。 / I understand that by participating in this service, I will not be able to attend sermons during the camp."
                  onChange={handleChange}
                />
              </FormGroup></Box>
              <Typography>与我们分享您在儿童崇拜方面的经验。 / Share with us your experience in children's worship.</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="experience"
                label="Experience"
                multiline
                rows={6}
              />
              {checked&&ageChecked ? <Button
              
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit Application
              </Button>:
              <Button
              disabled
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit Application
              </Button>}

              <Grid container>
                <Grid item xs></Grid>
                <Grid item></Grid>
              </Grid>
            </Box>
          
        </Container>
      </ThemeProvider>
    </>
  );
}

import React, { useState,useEffect } from "react";
import TextField from "@mui/material/TextField";
import {Typography} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Container,
  CssBaseline,
  InputAdornment,
  Button,
} from "@mui/material";
import { createTicket, getUserTicket } from "firebaseFunctions/firebase";
import TextCard from "components/TicketCard";
import { useRecoilValue } from "recoil";
import { phoneNumberAtom } from "recoil/account/atom";

const theme = createTheme();

  


export default function ContactUs() {

 
  const [title,setTitle]=useState();
  const [text,setText]=useState();
  const [tickets,setTickets]=useState([]);
  const [submitted,setSubmitted]=useState(false);
  const userId = useRecoilValue(phoneNumberAtom);

  useEffect(() => {
    getUserTicket(userId).then((res)=>{
      let tempTickets=[];
      res.forEach(ticket => {
        
        if(!ticket.isResolved){
  
          const cardTitle =new Date((ticket.submitted).seconds*1000).toLocaleDateString()
          setTitle(cardTitle)
          setText(ticket.issue)
          tempTickets.push(ticket)
        }
  
  
      });
      setTickets(tempTickets);

    })
  }, [submitted])
  

  const onSubmitHandle = (event) => {
    event.preventDefault();
    let ticketObject = {
      mobile: '+65'+event.target.phoneNumber.value,
      name: event.target.name.value,
      issue: event.target.issue.value,
    };
    createTicket(ticketObject);
    const tempArray=[ticketObject];
    setTickets(tempArray);
    setSubmitted(true);
  };
   
  
 

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          {tickets.length === 0 ? (
            <Box
              component="form"
              onSubmit={onSubmitHandle}
              noValidate
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                autoFocus
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                // error ={phoneError ? true : false }
                // helperText={phoneError ?  "Invalid Phone" :""}
                inputProps={{ maxLength: 8 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+65</InputAdornment>
                  ),
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="issue"
                label="Issue"
                multiline
                rows={6}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit Ticket
              </Button>

              <Grid container>
                <Grid item xs></Grid>
                <Grid item></Grid>
              </Grid>
            </Box>
          ) : (
            <Box
             
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography gutterBottom variant="h6" component="div" >
            Your Open Ticket
          </Typography>
             <TextCard title={title} text={text}></TextCard>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </>
  );
}

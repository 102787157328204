import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridEventListener,
} from "@mui/x-data-grid";
import { queryFullObject } from "firebaseFunctions/firebase";
import { db } from "firebase-config";
import { useEffect, useState } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import { Button } from "@mui/material";
import EditTicketModal from "./EditTicketModal";
import BasicTabs from "./Tabs";
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  { field: "issue", headerName: "Issue", width: 1000 },
  { field: "mobile", headerName: "Phone", width: 130 },
  { field: "submitted", headerName: "Submitted", width: 130 },
];

export default function TicketTable() {
  const [open, setOpen] = React.useState(false);
  const [tickets, setTickets] = useState([]);
  const [resolvedTickets, setResolvedTickets] = useState([]);
  const [ticketEdit, setTicketEdit] = useState();

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    const ticketIndexCondition = (element) => element.id === params.id;
    const ticketIndex = tickets.findIndex(ticketIndexCondition);

    setTicketEdit(tickets[ticketIndex]);

    setOpen(true);
  };

  const handleResolvedRowClick: GridEventListener<"rowClick"> = (params) => {
    const ticketIndexCondition = (element) => element.id === params.id;
    const ticketIndex = resolvedTickets.findIndex(ticketIndexCondition);

    setTicketEdit(resolvedTickets[ticketIndex]);

    setOpen(true);
  };

  const handleCloseModal = (event, data) => {
    setOpen(false);
  };

  let ticketsList = [];
  let resolvedTicketsList = [];
  const dbref = collection(db, "tickets");
  const processSnapshot = async (snapshot) => {
    let tempTicketsList = [];
    let tempResolvedTicketsList = [];
    snapshot.docs.forEach((doc, index) => {
      let docObject = doc.data();
      docObject.realId = doc.id;

      docObject.submitted = new Date(
        docObject.submitted.seconds * 1000
      ).toLocaleDateString();
      if (!docObject.isResolved) {
        docObject.id = tempTicketsList.length + 1;
        tempTicketsList.push(docObject);
      } else {
        docObject.id = tempResolvedTicketsList.length + 1;
        tempResolvedTicketsList.push(docObject);
      }
    });
    resolvedTicketsList = tempResolvedTicketsList;
    ticketsList = tempTicketsList;
    return ticketsList;
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(dbref, (snapshot) => {
      processSnapshot(snapshot);
      setTickets(ticketsList);
      setResolvedTickets(resolvedTicketsList);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <BasicTabs
        tab1={
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={tickets}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[25]}
              onRowClick={handleRowClick}
              //checkboxSelection
            />
            <EditTicketModal
              open={open}
              onCloseModal={handleCloseModal}
              ticket={ticketEdit}
            ></EditTicketModal>
          </div>
        }
        tab2={
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={resolvedTickets}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[25]}
              onRowClick={handleResolvedRowClick}
              //checkboxSelection
            />
            <EditTicketModal
              open={open}
              onCloseModal={handleCloseModal}
              ticket={ticketEdit}
            ></EditTicketModal>
          </div>
        }
      ></BasicTabs>
    </>
  );
}

import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Paper
  } from "@mui/material"
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
  
  const faqData = [
    {
      question_zh: "会有儿童节目吗？",
      question_en: "Will there be programmes for children?",
      ans_en:
        "There will be a children’s program for children of ages 4 to 12. If your child is under 4 years old, he/she will have to be under your supervision at the main sessions.",
      ans_zh:
        "4 至 12 岁 的儿童会有儿童活动。若孩子未满 4 岁，必须在由家长自己看顾。"
    },
    {
      question_zh: "我现在需要准备什么呢？",
      question_en: "What do I need to prepare now?",
      ans_en: (
        <ul>
          <li>Ensure that your passport is valid till at least 30 June 2025</li>
          <li>Consider your prefered mode of transport to and from KL</li>
          <li>Make bookings for travel if and when suitable</li>
          <li>Registration Window: 1 June 2024 - 31 July 2024</li>
        </ul>
      ),
      ans_zh: (
        <ul>
          <li>确保护照有效期至少到2025年6月30日</li>
          <li>考虑来回交通方式</li>
          <li>若决定自行，可在合适情况预订票</li>
          <li>报名日期： 2024年6月1日 - 2024年7月31日 </li>
        </ul>
      )
    },
    {
      question_zh: "不是教会会友，能够参加吗？",
      question_en: "Can non-church members register?",
      ans_en:
        "Currently, we only accept members and those who attend our church regularly (as well as their parents or non-believing family members)",
      ans_zh: "目前我们只接受会友以及本堂聚会的弟兄姐妹（还有父母或未信主的家人）报名。      "
    },
    {
      question_zh: "报名后能退出吗？",
      question_en: "Can I cancel my registration after registering?",
      ans_en:
        "Cancellations will be considered on a case-by-case basis. Please approach the camp committee for assistance.",
      ans_zh: "能否退出会以个案来处理。若想退出，请询问营会委员。"
    }
  ]
  
  export const FaqAccordian = () => {
    return (
      <>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" textAlign="center">
            常问问题 <br />
            Frequently Asked Questions
          </Typography>
        </Paper>
        {faqData.map(item => (
          <Accordion disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ userSelect: "text" }}
            >
              <Typography variant="h6" fontSize="body1.fontSize">
                {item.question_zh}
                <br />
                {item.question_en}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#f9f9f9", m: 1, pb: 1 }}>
              <Typography variant="body2" gutterBottom={false}>
                {item.ans_zh}
                <br />
                {item.ans_en}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    )
  }
  
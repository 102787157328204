import moment from 'moment';
import * as yup from 'yup';
const validationSchema = yup.object().shape({
  roomType: yup.string('Please select a room type').trim().required('Required'),

  guests: yup.array().of(
    yup.object().shape({
      chineseName: yup
        .string('Please provide your Chinese name')
        .trim()
        .required('Required'),
      name: yup.string('Please provide your English name').required('Required'),
      dateOfBirth: yup.date().max(moment().add(1, 'days')).required('Required'),
      gender: yup
        .string('Please select Male or Female')
        .trim()
        .required('Required'),
      contactNumber: yup
        .string('Please enter your contact number')
        .trim()
        .required('Required'),
      passportNumber: yup
        .string('Please enter your passport number')
        .trim()
        .required('Required'),
      passportExpiry: yup.date().required('Required'),
      nationality: yup
        .string('Please identify  your nationality')
        .trim()
        .required('Required'),
      emergencyContact: yup
        .string('Please specify an emergency contact')
        .trim()
        .required('Required'),
      relationship: yup
        .string('Please specify your relationship to the emergency contact')
        .trim()
        .required('Required'),
      emergencyContactNumber: yup
        .string(
          'Please provide the contact number of your listed emergency contact'
        )
        .trim()
        .required('Required'),
      edenBranch: yup
        .string('Please select a branch')
        .trim()
        .required('Required'),
      transport: yup
        .string('Please select a transport arrangment')
        .trim()
        .required('Required'),
    })
  ),

  // retypePassword: yup
  //   .string('Enter your password')
  //   .trim()
  //   .oneOf([yup.ref('password'), null], 'Passwords must match')
  //   .required('Required'),
});
export default validationSchema;

import {

    signInWithPhoneNumber,
    RecaptchaVerifier
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { useState,useEffect,useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth, addUsers } from "firebaseFunctions/firebase";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function OtpModal(props){
    const myRef = useRef();

   useEffect( () =>{
    setTimeout(() => {
        myRef.current.focus();
      }, 100)
   })
   
    return(
        <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    ><Box sx={modalStyle}>
            {props.verificationState === true ? <>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress /><Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ ml: 2 }}
                    >
                        Signing In
                    </Typography>
                </Box>
            </> :
                <>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Enter OTP
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        An OTP has been sent to your phone. Please input it below.
                    </Typography>
                    <TextField
                        autoFocus
                        inputRef={myRef}
                        margin="normal"
                        required
                        fullWidth
                        name="OTP"
                        label="OTP"
                        type="OTP"
                        id="OTP"
                        error ={props.otpError ? true : false }
                        helperText={props.otpError ?  "Invalid OTP" :"Enter 6 digit OTP"}
                        defaultValue=""
                        onChange={props.verifyOTP}

                    />
                </>}
        </Box>
    </Modal>
    )
}
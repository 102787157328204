import { Divider, Grid, Paper } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import checkoutFormModel from '../FormModel/bookRoomFormModel';

const labelMap = {
  roomType: 'Room type',
  extraBed: 'Extra bed',
  findRoommate: 'Find roommate?',
  chineseName: 'Chinese name',
  name: 'Name',
  dateOfBirth: 'Date of birth',
  gender: 'Gender',
  contactNumber: 'Contact number',
  passportNumber: 'Passport no.',
  passportExpiry: 'Passport expiry',
  nationality: 'Nationality',
  emergencyContact: 'Emergency contact',
  relationship: 'Relationship',
  emergencyContactNumber: 'Emergency contact no.',
  edenBranch: 'Place of worship',
  transport: 'Transport',
  specialRequests: 'Special requests',
};

const {
  roomType,
  extraBed,
  findRoommate,
  chineseName,
  name,
  dateOfBirth,
  gender,
  contactNumber,
  passportNumber,
  passportExpiry,
  nationality,
  emergencyContact,
  relationship,
  emergencyContactNumber,
  edenBranch,
  transport,
  specialRequests,
} = checkoutFormModel.formField;

export default function BookRoomFormSummary() {
  const formik = useFormikContext();
  const processProperty = (property) => {
    if (!property || property.length < 1) return '';

    let processedProperty = property;
    //Handle dates
    if (moment.isMoment(property)) {
      processedProperty = moment(property).format('DD/MM/YYYY');
      return processedProperty.toString();
    }
    // Handle all other data types
    return processedProperty.toString().toUpperCase();
  };

  const displaySummary = (values) => {
    return Object.keys(values).map((key) => {
      if (key !== 'id') {
        const property = values[`${key}`];
        if (Array.isArray(property)) {
          return property.map((guest, idx) => {
            return (
              <React.Fragment key={guest.name + guest.number}>
                <Grid container>
                  <Paper elevation={3} sx={{ marginTop: 3, p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component={'div'} variant="h6">
                          Occupant {idx + 1}
                        </Typography>
                      </Grid>
                      {displaySummary(guest)}
                    </Grid>
                  </Paper>
                </Grid>
              </React.Fragment>
            );
          });
        }

        return (
          <>
            {(key === 'emergencyContact' || key === 'edenBranch') && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Divider
                  sx={{
                    width: ' 80%',
                    height: '16px',
                  }}></Divider>
              </Grid>
            )}
            <Grid
              key={key}
              item
              xs={6}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'break-space',
              }}>
              <div>
                <Typography variant="h6">{labelMap[`${key}`]}</Typography>
              </div>
              <Box
                sx={{
                  minHeight: '2em',
                  backgroundColor: '#F5F5F5',
                }}>
                <Typography variant="body1">
                  {processProperty(property)}
                </Typography>
              </Box>
            </Grid>
          </>
        );
      }
    });
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar> */}
        <Box
          sx={{
            m: 3,
          }}>
          <Typography component="h1" variant="h5">
            Summary
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {displaySummary(formik.values)}
        </Grid>
      </Box>
    </Container>
  );
}

import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CartIconWithBadge from 'components/cart/CartIconWithBadge';
import { logout } from 'helper-functions/authentication';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';

// const pages = ['Products', 'Pricing', 'Blog'];
const pages = [];

class ButtonOptions {
  constructor(content = 'defaultContent', clickHandler = () => {}) {
    this.content = content;
    this.clickHandler = clickHandler;
  }
}

export default function ResponsiveAppBar() {
  const navigate = useNavigate();
  const shouldShowCart = Boolean(useRecoilValue(phoneNumberAtom));

  const logUserOut = () => {
    logout(navigate);
  };
  const settings = [
    new ButtonOptions('Account details'),
    new ButtonOptions('Logout', logUserOut),
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const emptyFunction = () => {};
  const handleCloseUserMenu =
    (uniqueClickHandler = emptyFunction) =>
    (e) => {
      uniqueClickHandler(navigate);
      setAnchorElUser(null);
    };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Avatar src="/edenlogo.jpg" sx={{display: { xs: 'flex', md: 'none' }, m: 1, bgcolor: "secondary.main", width: 30, height: 30 }}/>  
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate('/home')}
            sx={{
              cursor: 'pointer',
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}>
            Eden Camp 2024
          </Typography>

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          <Avatar src="/edenlogo.jpg" sx={{display: { xs: 'none', md: 'flex' }, m: 1, bgcolor: "secondary.main", width: 30, height: 30 }}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate('/home')}
            sx={{
              cursor: 'pointer',
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}>
            Eden Camp 2024
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                {page}
              </Button>
            ))}
          </Box>

          {shouldShowCart && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="View cart">
                <IconButton onClick={() => navigate('/cart')} sx={{ p: 0 }}>
                  <CartIconWithBadge />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu()}>
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.content}
                    onClick={handleCloseUserMenu(setting.clickHandler)}>
                    <Typography textAlign="center">
                      {setting.content}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {/*  */}
          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu()}>
              {settings.map((setting) => (
                <MenuItem
                  key={setting.content}
                  onClick={handleCloseUserMenu(setting.clickHandler)}>
                  <Typography textAlign="center">{setting.content}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

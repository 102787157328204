import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC8bJUgDk5I-PHuT-Ob784A9Wbet8XmmUo",
    authDomain: "edencamp-2022.firebaseapp.com",
    databaseURL: "https://edencamp-2022-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "edencamp-2022",
    storageBucket: "edencamp-2022.appspot.com",
    messagingSenderId: "188710728944",
    appId: "1:188710728944:web:8bfd4f05d8397856647e35",
    measurementId: "G-CFYDBWD17P"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
 export const db = getFirestore(app);
 
import { v4 as uuidv4 } from 'uuid';
import checkoutFormModel from './bookRoomFormModel';
const {
  roomType,
  extraBed,
  findRoommate,
  chineseName,
  name,
  dateOfBirth,
  gender,
  contactNumber,
  passportNumber,
  passportExpiry,
  nationality,
  emergencyContact,
  relationship,
  emergencyContactNumber,
  edenBranch,
  transport,
  specialRequests,
} = checkoutFormModel.formField;
const getKey = () => uuidv4();
export const individualGuestInitialValues = () => ({
  id: uuidv4(),
  chineseName: '',
  name: '',
  dateOfBirth: '',
  gender: '',
  contactNumber: '',
  passportNumber: '',
  passportExpiry: '',
  nationality: '',
  emergencyContact: '',
  relationship: '',
  emergencyContactNumber: '',
  edenBranch: '',
  transport: '',
  specialRequests: '',
});

//eslint-disable-next-line
export default {
  [roomType.name]: '',
  [extraBed.name]: false,
  [findRoommate.name]: false,
  guests: [individualGuestInitialValues()],
};

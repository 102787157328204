import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, MenuItem, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import { onSnapshot} from 'firebase/firestore';
import { dbref, mergeRooms } from 'firebaseFunctions/firebase'; // Update this import

function calculateAge(birthDateSeconds) {
  if (!birthDateSeconds) return 'Unknown';
  const birthDate = new Date(birthDateSeconds * 1000);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function MergerUI({ data }) {
  const [eligibleRooms, setEligibleRooms] = useState([]);
  const [primaryRoom, setPrimaryRoom] = useState('');
  const [secondaryRoom, setSecondaryRoom] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const filtered = data.flatMap(item => 
        item.rooms ? item.rooms.filter(room => room.findRoommate && room.verifiedPayment) : []
      );
      //console.log("Filtered rooms:", filtered);
      setEligibleRooms(filtered);
    }
  }, [data]);

  const columns = [
    { field: 'name', headerName: 'Guest Name', width: 200 },
    { field: 'age', headerName: 'Age', width: 100 },
    { field: 'gender', headerName: 'Gender', width: 200 },
    { field: 'placeOfWorship', headerName: 'Place of Worship', width: 200 },
    { field: 'nationality', headerName: 'Nationality', width: 150 },
  ];

  const rows = eligibleRooms.flatMap((room, roomIndex) => 
    (room.guests || []).map((guest, guestIndex) => ({
      id: `${room.bookingId}-${guestIndex}`,
      name: guest.name || 'Unknown',
      gender: guest.gender || 'Unknown',
      age: guest.dateOfBirth ? calculateAge(guest.dateOfBirth.seconds) : 'Unknown',
      placeOfWorship: guest.edenBranch || 'Unknown',
      nationality: guest.nationality || 'Unknown',
    }))
  );

 // console.log("Generated rows:", rows);

  const isMergeDisabled = !primaryRoom || !secondaryRoom || primaryRoom === secondaryRoom || isLoading;

  const handleMerge = () => {
    if (!isMergeDisabled) {
      setOpenConfirm(true);
    }
  };

  const confirmMerge = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await mergeRooms(primaryRoom, secondaryRoom);
      if (result.success) {
        console.log('Rooms merged successfully');
        // Update local state
        setEligibleRooms(prevRooms => 
          prevRooms.map(room => 
            room.bookingId === primaryRoom ? result.mergedRoom : room
          ).filter(room => room.bookingId !== secondaryRoom)
        );
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error merging rooms:', error);
      setError(`Failed to merge rooms: ${error.message}`);
    } finally {
      setIsLoading(false);
      setOpenConfirm(false);
      setPrimaryRoom('');
      setSecondaryRoom('');
    }
  };



  

  return (
    <div style={{ height: '400px', width: '100%' }}>
      {eligibleRooms.length > 0 ? (
        <>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableSelectionOnClick
            sx={{ height: 400 }}
          />
          <div style={{ marginTop: 20 }}>
            <Select
              value={primaryRoom}
              onChange={(e) => setPrimaryRoom(e.target.value)}
              displayEmpty
              style={{ marginRight: 10 }}
            >
              <MenuItem value="">Select Primary Room</MenuItem>
              {eligibleRooms.map(room => (
                <MenuItem key={room.bookingId} value={room.bookingId}>
                  {room.guests[0].name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={secondaryRoom}
              onChange={(e) => setSecondaryRoom(e.target.value)}
              displayEmpty
              style={{ marginRight: 10 }}
            >
              <MenuItem value="">Select Secondary Room</MenuItem>
              {eligibleRooms.map(room => (
                <MenuItem key={room.bookingId} value={room.bookingId}>
                  {room.guests[0].name}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              onClick={handleMerge}
              disabled={isMergeDisabled}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Merge'}
            </Button>
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Dialog
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
          >
            <DialogTitle>Confirm Merge</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to merge these rooms?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
              <Button onClick={confirmMerge} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <p>No eligible rooms for merging found.</p>
      )}
    </div>
  );
  
}

function MergerTool() {
  const [bookingsList, setBookingsList] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(dbref, async (snapshot) => {
      let accountsList = [];
      snapshot.docs.forEach((doc) => {
        let docObject = doc.data();
        docObject.id = doc.id;
        accountsList.push(docObject);
      });
      setBookingsList(accountsList);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <MergerUI data={bookingsList} />;
}

export default MergerTool;
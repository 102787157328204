import * as React from "react"
import Dialog from "@mui/material/Dialog"
import {
  Card,
  CardActionArea,
  CardContent,
  DialogContent,
  Typography
} from "@mui/material"
import Grid from "@mui/material/Grid"

const SimpleDialog = ({ open, content, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}
export const SummaryCard = ({ content, label, details }) => {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Card elevation='3' sx={[
        { height: "100%", cursor: details ? 'pointer' : 'default'},
        details && { '&:hover': { boxShadow: 20}, }
      ]}>
        <CardActionArea
          disabled={details ? false : true}
          sx={{ height: "inherit", userSelect: "text" }}
          onClick={handleClickOpen}
        >
          <CardContent
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <Grid
              container
              sx={{ height: "100%" }}
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item flexGrow="1" textAlign="center" alignContent="center">
                <Typography variant="h4">{content}</Typography>
              </Grid>
              <Grid item textAlign="center" pb="10px">
                <Typography
                  fontSize="h6.fontSize"
                  variant="overline"
                  color="text.secondary"
                >
                  {label}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      {details ? (
        <SimpleDialog
          content={details}
          open={open}
          onClose={handleClose}
        ></SimpleDialog>
      ) : null}
    </>
  )
}

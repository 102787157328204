import React from 'react';
import SignUpForm from 'components/SignUpForm';

function SignUp() {
  return (
    <>
      <SignUpForm />
    </>
  );
}

export default SignUp;

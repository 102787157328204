import { roomCosts } from 'constants';
import moment from 'moment';

const {
  COST_PER_ADULT,
  COST_PER_ADULT_ALONE,
  COST_PER_CHILD,
  COST_PER_CHILD_ON_EXTRA_BED,
} = roomCosts;
export function testGetRoomCost() {
  const testCases = [
    {
      description: '1 Adult (no extra bed, not finding roommate)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 600
    },
    {
      description: '1 Adult (no extra bed, finding roommate)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' }
        ],
        findRoommate: true,
        extraBed: false
      },
      expectedCost: 380
    },
    {
      description: '1 Adult + 1 Child (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 750
    },
    {
      description: '2 Adults (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 760
    },
    {
      description: '1 Adult + 1 Child + 1 Infant (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2022-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 750
    },
    {
      description: '1 Adult + 2 Children (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2012-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 900
    },
    {
      description: '1 Adult + 2 Children (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2012-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1010
    },
    {
      description: '2 Adults + 1 Child (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2015-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 910
    },
    {
      description: '2 Adults + 1 Child (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2015-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1020
    },
    {
      description: '3 Adults (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2000-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 1020
    },
    {
      description: '3 Adults (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2000-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1020
    },
    {
      description: '1 Adult + 1 Child + 2 Infants (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2022-01-01' },
          { dateOfBirth: '2023-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 750
    },
    {
      description: '1 Adult + 2 Children + 1 Infant (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2012-01-01' },
          { dateOfBirth: '2022-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 900
    },
    {
      description: '1 Adult + 2 Children + 1 Infant (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2012-01-01' },
          { dateOfBirth: '2022-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1010
    },
    {
      description: '2 Adults + 1 Child + 1 Infant (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2022-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 910
    },
    {
      description: '2 Adults + 1 Child + 1 Infant (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2022-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1020
    },
    {
      description: '2 Adults + 2 Children (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2012-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 1060
    },
    {
      description: '2 Adults + 2 Infants (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2021-01-01' },
          { dateOfBirth: '2021-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1020
    },{
      description: '2 Adults + 2 Children (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2015-01-01' },
          { dateOfBirth: '2012-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1170
    },
    {
      description: '3 Adults + 1 Child (no extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2000-01-01' },
          { dateOfBirth: '2015-01-01' }
        ],
        findRoommate: false,
        extraBed: false
      },
      expectedCost: 1170
    },
    {
      description: '3 Adults + 1 Child (with extra bed)',
      formValues: {
        guests: [
          { dateOfBirth: '1990-01-01' },
          { dateOfBirth: '1995-01-01' },
          { dateOfBirth: '2000-01-01' },
          { dateOfBirth: '2015-01-01' }
        ],
        findRoommate: false,
        extraBed: true
      },
      expectedCost: 1170
    }
  ];

  testCases.forEach((testCase) => {
    const { description, formValues, expectedCost } = testCase;
    const actualCost = getRoomCost(formValues);
    const passed = actualCost === expectedCost;
    console.log(`Test Case: ${description}`);
    console.log(`Expected Cost: ${expectedCost}`);
    console.log(`Actual Cost: ${actualCost}`);
    console.log(`Passed: ${passed}`);
    console.log('------------------------');
  });
}

function calculateTwoPaxCost(guests) {
  return guests.reduce((prevValue, guest) => {
    const age = moment().diff(guest.dateOfBirth, 'years');
    const isChild = age <= 12;
    const cost = isChild ? COST_PER_CHILD : COST_PER_ADULT;
    return prevValue + cost;
  }, 0);
}

// Adults are defined as 18 and over
export function calculateNumAdultsAndChildrenForRooming(guests) {
  // underage are people under 18
  const tracker = { ofAge: 0, underage: 0 };
  guests.forEach((guest) => {
    let key = 'ofAge';
    // Round down because we are looking at age by year and not month
    const age = Math.floor(moment('2024-12-31 23:59:26.123').diff(guest.dateOfBirth, 'years', true));
    const isUnderage = age < 13;
    if (isUnderage) key = 'underage';
    tracker[key] += 1;
  });
  return tracker;
}

// Adults are defined as 13 and over
export function calculateNumAdultsAndChildrenForCosting(guests, dateIsFirebaseTimestamp = false) {
  const tracker = { adult: 0, child: 0, infantOrToddler: 0 };
  guests.forEach((guest) => {
    let key = 'adult';
    const dOB = dateIsFirebaseTimestamp ? guest.dateOfBirth.toDate() : guest.dateOfBirth;
    // Round down because we are looking at age by year and not month
    const age = Math.floor(moment('2024-12-31 23:59:26.123').diff(dOB, 'years', true));

    const isChild = age >= 5 && age <= 12;
    const infantOrToddler = age < 5;
    if (isChild) key = 'child';
    if (infantOrToddler) key = 'infantOrToddler';
    tracker[key] += 1;
  });
  return tracker;
}

export default function getRoomCost(formValues) {
  const { guests, findRoommate, extraBed } = formValues;
  const numOccupants = guests.length;
  const { adult, child, infantOrToddler } = calculateNumAdultsAndChildrenForCosting(guests);

  // Sort guests by age in descending order
  const sortedGuests = guests.sort((a, b) => {
    const ageA = moment('2024-12-31 23:59:26.123').diff(a.dateOfBirth, 'years', true);
    const ageB = moment('2024-12-31 23:59:26.123').diff(b.dateOfBirth, 'years', true);
    return ageB - ageA;
  });

  let totalCost = 0;

   // 1 Adult Room
   if (adult === 1) {
    if (findRoommate) {
      totalCost += COST_PER_ADULT;
    } else {
      totalCost += COST_PER_ADULT_ALONE;
    }

    if (child > 0) {
      // Handle 1 Adult and 1 Child (5 - 12 years) in a Twin/King room
      if (numOccupants - infantOrToddler === 2 && adult === 1 && child === 1) {
        return COST_PER_ADULT_ALONE + COST_PER_CHILD_ON_EXTRA_BED;
      }

      // Handle 1 Adult and 2 Children (5 - 12 years) (w/o bed) in a Twin/King room
      if (numOccupants - infantOrToddler === 3 && adult === 1 && child === 2 && !extraBed) {
        return COST_PER_ADULT_ALONE + COST_PER_CHILD_ON_EXTRA_BED  * 2;
      }

      if (extraBed) {
        totalCost += COST_PER_CHILD_ON_EXTRA_BED;
        totalCost += (child - 1) * COST_PER_CHILD;
      } else {
        totalCost += child * COST_PER_CHILD;
      }
    }
  }

  // 2 Adult Room
  if (adult === 2) {
    totalCost += COST_PER_ADULT * 2;

   

    if (child > 0) {
      if (extraBed) {
         // Handle 2 Adults and 2 Children (only 1 extra bed) in a Twin/King room
            if (numOccupants === 4 && adult === 2 && child === 2 && extraBed) {
            return COST_PER_ADULT * 2 + COST_PER_CHILD + COST_PER_CHILD_ON_EXTRA_BED;
            }
        totalCost += COST_PER_CHILD;
      } else {
        totalCost += child * COST_PER_CHILD_ON_EXTRA_BED;
      }
    } else if (extraBed) {
      totalCost += COST_PER_CHILD;
    }
  }

  // 3 Adult Room
  if (adult === 3) {
    totalCost += COST_PER_ADULT * 2;
    totalCost += COST_PER_CHILD;

    if (child > 0) {
      totalCost += COST_PER_CHILD_ON_EXTRA_BED;
    }
  }
  
  return totalCost;
}

import { Grid } from "@mui/material";
import { calculateNumAdultsAndChildrenForCosting } from "components/book-room.v2/BookRoomV2/FormUtils/getRoomCost";
import { roomCosts } from "constants";
import getRoomCost from "components/book-room.v2/BookRoomV2/FormUtils/getRoomCost";

export default function CartBodyItemisedBreakdown({
  unpaidBooking,
  numCoachTickets,
}) {
  const {
    COST_PER_ADULT,
    COST_PER_ADULT_ALONE,
    COST_PER_CHILD,
    COST_PER_CHILD_ON_EXTRA_BED,
    COST_PER_COACH_ONE_WAY_TICKET,
    COST_PER_COACH_ROUND_TRIP_TICKET,
  } = roomCosts;

  const {
    adult: numAdults,
    child: numChildren,
    infantOrToddler: numInfantOrToddler,
  } = calculateNumAdultsAndChildrenForCosting(unpaidBooking.guests, true);

  // Calculate the room cost
  const roomCost = unpaidBooking.roomCost;
  const transportCost = unpaidBooking.transportCost;

  const getBreakdown = (num, singularCategory, pluralCategory, cost) => {
    if (num < 1) return "";
    let category = singularCategory;
    if (num > 1) {
      category = pluralCategory;
    }
    return `${num} ${category} @ SGD ${cost}`;
  };

  // Calculate the number of each type of coach tickets
  const getNumCoachTicketsByType = () => {
    let numRoundTrip = 0;
    let numOneWay = 0;

    unpaidBooking.guests.forEach((guest) => {
      const transport = guest.transport.toUpperCase();
      if (transport === "COACH-ROUND-TRIP") {
        numRoundTrip += 1;
      } else if (
        transport === "COACH-OUTGOING" ||
        transport === "COACH-RETURN"
      ) {
        numOneWay += 1;
      }
    });

    return { numRoundTrip, numOneWay };
  };

  const { numRoundTrip, numOneWay } = getNumCoachTicketsByType();

  return (
    <Grid container>
      <Grid item xs={12}>
        <h4 style={{ margin: 0 }}>Room Pricing: SGD {roomCost}</h4>
      </Grid>
      {numAdults === 1 && unpaidBooking.roomType === "Single" && (
        <Grid item xs={12}>
          {getBreakdown(
            1,
            "Adult (Single)",
            "Adult (Single)",
            COST_PER_ADULT_ALONE
          )}
        </Grid>
      )}
      {numAdults === 1 && unpaidBooking.roomType !== "Single" && numInfantOrToddler === 0 && numChildren === 0 && (
        <Grid item xs={12}>
          {getBreakdown(1, "Adult", "Adult", COST_PER_ADULT)}
        </Grid>
      )}
      {numAdults === 1 &&
        numInfantOrToddler > 0 && (
          <>
            <Grid item xs={12}>
              {getBreakdown(1, "Adult", "Adult", COST_PER_ADULT_ALONE)}
            </Grid>
            <Grid item xs={12}>
              {getBreakdown(
                numInfantOrToddler,
                "Infant/Toddler",
                "Infants/Toddlers",
                0
              )}
            </Grid>
          </>
        )}
      {numAdults === 1 &&
        numChildren >0 && !unpaidBooking.extraBed &&  (
          <>
            <Grid item xs={12}>
              {getBreakdown(
                1,
                "Adult (Single)",
                "Adult (Single)",
                COST_PER_ADULT_ALONE
              )}
            </Grid>
            <Grid item xs={12}>
              {getBreakdown(
                1,
                "Child (no extra bed)",
                "Child (no extra bed)",
                COST_PER_CHILD
              )}
            </Grid>
          </>
        )}
      {numAdults === 1 && numChildren > 0 && unpaidBooking.extraBed && (
        <>
          <Grid item xs={12}>
            {getBreakdown(1, "Adult", "Adult", COST_PER_ADULT_ALONE)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (extra bed)",
              "Child (extra bed)",
              COST_PER_CHILD
            )}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (no extra bed)",
              "Child (no extra bed)",
              COST_PER_CHILD_ON_EXTRA_BED
            )}
          </Grid>
        </>
      )}
      {numAdults === 2 && numChildren === 0 &&(
        <Grid item xs={12}>
          {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
        </Grid>
      )}
      {numAdults === 2 && numChildren === 1 && unpaidBooking.extraBed && (
        <>
          <Grid item xs={12}>
            {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (extra bed)",
              "Child (extra bed)",
              COST_PER_CHILD
            )}
          </Grid>
        </>
      )}
      {numAdults === 2 && numChildren === 1 && !unpaidBooking.extraBed && (
        <>
          <Grid item xs={12}>
            {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (no extra bed)",
              "Child (no extra bed)",
              COST_PER_CHILD_ON_EXTRA_BED
            )}
          </Grid>
        </>
      )}
      {numAdults === 3 && numChildren ===0 && (
        <>
          <Grid item xs={12}>
            {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Adult (extra bed)",
              "Adult (extra bed)",
              COST_PER_CHILD
            )}
          </Grid>
        </>
      )}
      {numAdults === 3 && numChildren === 1 && unpaidBooking.extraBed && (
        <>
          <Grid item xs={12}>
            {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Adult (extra bed)",
              "Adult (extra bed)",
              COST_PER_CHILD
            )}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (no extra bed)",
              "Child (no extra bed)",
              COST_PER_CHILD_ON_EXTRA_BED
            )}
          </Grid>
        </>
      )}
      {numAdults === 2 && numChildren === 2 && unpaidBooking.extraBed && (
        <>
          <Grid item xs={12}>
            {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (extra bed)",
              "Child (extra bed)",
              COST_PER_CHILD
            )}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              1,
              "Child (no extra bed)",
              "Child (no extra bed)",
              COST_PER_CHILD_ON_EXTRA_BED
            )}
          </Grid>
        </>
      )}
      {numAdults === 2 && numChildren === 2 && !unpaidBooking.extraBed && (
        <>
          <Grid item xs={12}>
            {getBreakdown(2, "Adult", "Adults", COST_PER_ADULT)}
          </Grid>
          <Grid item xs={12}>
            {getBreakdown(
              2,
              "Child (no extra bed)",
              "Children (no extra bed)",
              COST_PER_CHILD_ON_EXTRA_BED
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <h4 style={{ margin: 0 }}> </h4>
      </Grid>
      <Grid item xs={12}>
        <h4 style={{ margin: 0 }}>Transport Pricing: SGD {transportCost}</h4>
      </Grid>
      {numRoundTrip > 0 && (
        <Grid item xs={12}>
          {getBreakdown(
            numRoundTrip,
            "round-trip coach ticket",
            "round-trip coach tickets",
            COST_PER_COACH_ROUND_TRIP_TICKET
          )}
        </Grid>
      )}
      {numOneWay > 0 && (
        <Grid item xs={12}>
          {getBreakdown(
            numOneWay,
            "one-way coach ticket",
            "one-way coach tickets",
            COST_PER_COACH_ONE_WAY_TICKET
          )}
        </Grid>
      )}
      <Grid item xs={12}></Grid>
    </Grid>
  );
}

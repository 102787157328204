const TRANSPORT_COST_PER_PAX = 40;
const TRANSPORT_COST_ROUND_TRIP = 65;

export default function getTransportCost(formValues) {
  return formValues.guests.reduce((prevValue, guest) => {
    const transport = guest.transport.toString().toUpperCase();
    let transportCost = 0;

    if (transport === 'COACH-ROUND-TRIP') {
      transportCost = TRANSPORT_COST_ROUND_TRIP;
    } else if (transport === 'COACH-OUTGOING' || transport === 'COACH-RETURN') {
      transportCost = TRANSPORT_COST_PER_PAX;
    }

    return prevValue + transportCost;
  }, 0);
}

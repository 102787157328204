import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';

export default function CartBodyRoomDetails({
  unpaidBooking,
  setNumCoachTickets,
}) {
  const getOccupants = () => {
    let occupantsList = [];
    unpaidBooking.guests.forEach((guest, idx) => {
      occupantsList.push(guest.chineseName);
    });
    return occupantsList.toString();
  };

  const getGuestsNeedingTransport = () => {
    let guestNames = [];
    unpaidBooking.guests.forEach((guest) => {
      if (guest.transport.toUpperCase() === 'COACH') {
        guestNames.push(guest.chineseName);
      }
    });
    return guestNames;
  };

  useEffect(() => {
    const guestsNeedingTransport = getGuestsNeedingTransport();
    setNumCoachTickets(guestsNeedingTransport.length);
  }, [unpaidBooking, setNumCoachTickets]);

  const displayDetails = (header, content, shouldLineBreak = false) => {
    return (
      <Grid container sx={{ width: '100%' }}>
        <Grid item>
          <Typography component="span">{header}</Typography>
          <Typography
            component={shouldLineBreak ? 'div' : 'span'}
            sx={{ ml: 2 }}>
            {content}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const guestsNeedingTransport = getGuestsNeedingTransport();

  return (
    <>
      <Grid
        container
        sx={{
          width: '100%',
          overflow: 'hidden',
          overflowWrap: 'anywhere',
        }}
        direction="column">
        <Grid
          item
          sx={{
            width: '100%',
          }}>
          {displayDetails('Room type:', unpaidBooking.roomType)}
          {unpaidBooking.extraBed &&
            displayDetails('Extra bed:', unpaidBooking.extraBed ? 'Yes' : '')}
          {displayDetails('Occupants:', getOccupants(), true)}
          {displayDetails(
            guestsNeedingTransport.length ? 'Registered for coach:' : '',
            guestsNeedingTransport.toString(),
            true
          )}{' '}
        </Grid>
      </Grid>
    </>
  );
}
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'firebaseFunctions/firebase';

import { selector } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';
import { unpaidBookingsAtom } from './atom';

const getUnpaidBookings = selector({
  key: 'getUnpaidBookings',
  get: async ({ get }) => {
    const phoneNumber = get(phoneNumberAtom);

    try {
      const ref = doc(db, 'account', phoneNumber);
      const account = (await getDoc(ref)).data();
      const unpaidBookings = [];
      account.rooms.forEach((room) => {
        if (!room.hasPaid) {
          unpaidBookings.push(room);
        }
      });
      return unpaidBookings;
    } catch (error) {
      console.error(error);
    }
  },
  set: ({ set }, newValue) => set(unpaidBookingsAtom, newValue),
});

export default getUnpaidBookings;

import 'ComingSoon.css';
import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EastIcon from '@mui/icons-material/East';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlightIcon from '@mui/icons-material/Flight';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import WestIcon from '@mui/icons-material/West';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { AccomodationDetails } from 'components/landingPage/AccomodationDetails';
import { FaqAccordian } from 'components/landingPage/Faq';
import { LocationDetails } from "components/landingPage/LocationDetails";
import { SummaryCard } from '../components/landingPage/SummaryCard';
import { TransportDetails } from '../components/landingPage/TransportDetails';


const StyledAccordian = styled(Accordion, { skipVariantsResolver: true })(({ theme }) => ({
  "&.Mui-disabled": {
    background: theme.palette.background.paper,
    opacity: 1
  },
  ".MuiAccordionSummary-root.Mui-disabled": {
    opacity: 1
  }
}));

const pricing_card = (
  <Grid container sx={{ height: '100%' }} rowSpacing='10' direction='row' justifyContent='space-around' alignItems='stretch'>
    <Grid item>
      Adult: $380   <Typography fontSize="h6.fontSize" variant="caption">onwards
      </Typography>
    </Grid>
    <Grid item>
      Children: $150   <Typography fontSize="h6.fontSize" variant="caption">onwards
      </Typography>
    </Grid>
  </Grid>

);

const transport_card = (
  <Grid container sx={{ height: '100%' }} rowSpacing='10' direction='row' justifyContent='space-around' alignItems='stretch'>
    <Grid item>
      <Box>
        Bus 2-way: $65 <br />
        <DirectionsBusIcon /><SwapHorizIcon /><LocationCityIcon />
      </Box>

    </Grid>
    <Grid item>
      <Box>
        Bus 1-way: $40 <br />
        <DirectionsBusIcon /><EastIcon /><LocationCityIcon />
        /<DirectionsBusIcon /><WestIcon /><LocationCityIcon />
      </Box>
    </Grid>
    <Grid item>
      <Box>
        Others: Self-funded<br />
        <DirectionsCarIcon />/<FlightIcon />/<MoreHorizIcon />
      </Box>
    </Grid>
  </Grid >

);


const SummaryAccordian = ({ content, label, details }) => {
  return (
    <StyledAccordian disabled={details ? false : true}>
      <AccordionSummary expandIcon={details ? <ExpandMoreIcon /> : null} sx={{ userSelect: 'text' }}>
        <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' fontSize='body1.fontSize'>{content}</Typography>
          <Typography variant="overline" color="text.secondary" textAlign='right'>{label}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {details}
      </AccordionDetails>
    </StyledAccordian>
  );
}

const pricing_accordian = (
  <Stack spacing={1}>
    <Box>
      Adult: $380   <Typography fontSize="body2.fontSize" variant="caption">onwards
      </Typography>
    </Box>
    <Box>
      Children: $150   <Typography fontSize="body2.fontSize" variant="caption">onwards
      </Typography>
    </Box>
  </Stack>

);


const transport_accordian = (
  <Grid container alignItems='center' spacing={1} minWidth='300px'>
    <Grid item xs={2} minWidth='70px'>
      <DirectionsBusIcon fontSize='small' /><SwapHorizIcon fontSize='small' /><LocationCityIcon fontSize='small' />
    </Grid>
    <Grid item xs={9}>
      &nbsp;Bus 2-way: $65
    </Grid>
    <Grid item xs={2} minWidth='70px'>
      <Box display='inline-block' sx={{ verticalAlign: 'middle' }}>
        <DirectionsBusIcon fontSize='small' /><EastIcon fontSize='small' /><LocationCityIcon fontSize='small' />
        <Divider sx={{ m: -1.5, mb: -.5, opacity: 1, borderColor: 'darkgray' }} variant='middle'><Typography>or</Typography></Divider>
        <DirectionsBusIcon fontSize='small' /><WestIcon fontSize='small' /><LocationCityIcon fontSize='small' />
      </Box>
    </Grid>
    <Grid item xs={9}>
      &nbsp;Bus 1-way: $40
    </Grid>
    <Grid item xs={2} minWidth='70px'>
      <Box display='inline-block' sx={{ verticalAlign: 'middle' }}>
        <DirectionsCarIcon fontSize='small' />
        /<FlightIcon fontSize='small' />
        /<MoreHorizIcon fontSize='small' />
      </Box>
    </Grid>
    <Grid item xs={9}>
      &nbsp;Others: Self-funded
    </Grid>
  </Grid>

);

export default function ComingSoon() {
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (matches) {
    return (
      <>
        <Container maxWidth="xl">

          <Grid container spacing={2} alignItems="stretch" p={5}>
            <Grid item xs={12} textAlign="center">
              <img src="/edenlogo.jpg" className="coming-soon-logo" alt="logo" />
              <img src="/LMLogo.svg" className="coming-soon-logo" alt="logo" />
            </Grid>
            <Grid item xs={3} xl={2}>
              <SummaryCard content={'合神心意'} label='主题 Theme' ></SummaryCard>
            </Grid>
            <Grid item xs={4} xl={2}>
              <SummaryCard content={'张赐福牧师'} label='讲员 Speaker'></SummaryCard>
            </Grid>
            <Grid item xs={5} xl={4}>
              <SummaryCard content={'16–19 Dec 2024'} label='日期 Date'></SummaryCard>
            </Grid>
            <Grid item xs={4} xl={4}>
              <SummaryCard content={'Le Meridien Putrajaya, Kuala Lumpur'} label='地点 Location ⮟' details={LocationDetails}></SummaryCard>
            </Grid>
            <Grid item xs={8} xl={6}>
              <SummaryCard content={pricing_card} label='住宿 Accomodation ⮟' details={AccomodationDetails}></SummaryCard>
            </Grid>
            <Grid item xs={12} xl={6}>
              <SummaryCard content={transport_card} label='交通 Transport ⮟' details={TransportDetails}></SummaryCard>
            </Grid>
            <Grid item xs={12} mx={10} my={5}>
              <Button fullWidth size='large' onClick={() =>
                navigate('/home')}
                variant="contained"> <Typography
                  fontSize="h6.fontSize"
                >
                  报名/登录<br />Register/Login
                </Typography></Button>
            </Grid>
            <Grid item xs={12}>

              <FaqAccordian />
            </Grid>
          </Grid>
        </Container>

      </>);
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center" m="10px">
          <img src="/edenlogo.jpg" className="coming-soon-logo" alt="logo" />
          <img src="/LMLogo.svg" className="coming-soon-logo" alt="logo" />
        </Grid>
        <Grid item xs={12}>
          <SummaryAccordian content={'合神心意'} label='主题 Theme' />
          <SummaryAccordian content={'张赐福牧师'} label='讲员 Speaker' />
          <SummaryAccordian content={'16–19 Dec 2024'} label='日期 Date' />
          <SummaryAccordian content={'Le Meridien Putrajaya, Kuala Lumpur'} label='地点 Location' details={LocationDetails} />
          <SummaryAccordian content={pricing_accordian} label='住宿 Accomodation' details={AccomodationDetails} />
          <SummaryAccordian content={transport_accordian} label='交通 Transport' details={TransportDetails} />
        </Grid>
        <Grid item xs={12} mx={10} my={1}>
          <Button fullWidth size='large' onClick={() =>
            navigate('/home')}
            variant="contained">
            报名/登录<br />Register/Login
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FaqAccordian />
        </Grid>
      </Grid>
    </>);

  return (
    <div className="coming-soon">
      <header className="coming-soon-header">
        <div className="coming-soon-logo-box">
          <img src="/edenlogo.jpg" className="coming-soon-logo" alt="logo" />
          <img src="/LMLogo.svg" className="coming-soon-logo" alt="logo" />
        </div>







        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <br></br>
      <div className='grouper'>
        <div className='rules'>  <p> 请阅读以下内容： </p>

          <p>1）每间客房最多只能容纳2名成人</p>
          <p>2）每间客房必须至少有1名付费的成人（> 12岁）。</p>
          <p>3）报名之前请确保个人资料准确无误。成交之后就不能更改。</p>
          <p>4）如果您的护照有效期在营会日期前少过6个月，您仍然可以注册，但请记得更新护照，然后通知营会的委员。 </p>
          <p> 5）此表格最好在Chrome或Safari浏览器中查看。 不要使用Internet Explorer。</p>
          <br></br>
          <p>  Before proceeding, please read the following:</p>

          <p>1）There can only be a maximum of 2 adults per room .</p>
          <p>2）There must be at least 1 paying adult per room ( >12 years old).</p>
          <p>3）Please ensure that all details entered are accurate before submitting. Editing is not allowed</p>
          <p>4）If your passport expiry date is within 6 months from the camp, you can still register, but please do remember to renew your passport, and then inform our committee of your new details. </p>
          <p> 5） This form is best viewed in Chrome or Safari. Do not use Internet Explorer.</p>
          <div className='button-spacer'><Button onClick={() =>
            navigate('/home')}
            variant="contained">Register</Button></div>
        </div>

      </div>
    </div>

  );
}

import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'firebaseFunctions/firebase';
import React, { useState, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { phoneNumberAtom } from 'recoil/account/atom';
import { unpaidBookingsAtom } from 'recoil/booking/atom';
import CartBodyItemisedBreakdown from './CartBodyItemisedBreakdown';
import CartBodyRoomDetails from './CartBodyRoomDetails';

const updateGuests = async (userId, updatedRooms) => {
  try {
    const userRef = doc(db, 'account', userId);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      const currentRooms = userDoc.data().rooms || [];
      
      // Update only the rooms that have changed
      const newRooms = currentRooms.map(room => {
        const updatedRoom = updatedRooms.find(r => r.bookingId === room.bookingId);
        return updatedRoom || room;
      });
      
      // Add any new rooms that weren't in the original list
      updatedRooms.forEach(room => {
        if (!newRooms.some(r => r.bookingId === room.bookingId)) {
          newRooms.push(room);
        }
      });

      await updateDoc(userRef, { rooms: newRooms });
      console.log("Guests updated successfully");
    } else {
      console.error("User document does not exist");
    }
  } catch (error) {
    console.error("Error updating guests:", error);
  }
};

const removeGuest = (unpaidBookings, unpaidBookingId) => {
  return unpaidBookings.filter(
    (booking) => booking.bookingId !== unpaidBookingId
  );
};

export default function CartBody() {
  const userId = useRecoilValue(phoneNumberAtom);
  const [numCoachTickets, setNumCoachTickets] = useState(0);
  const unpaidBookings = useRecoilValue(unpaidBookingsAtom);
  const setUnpaidBookings = useSetRecoilState(unpaidBookingsAtom);

  const handleRemoveUnpaidBooking = useCallback(async (unpaidBooking) => {
    try {
      // Remove from local state
      const newGuestList = removeGuest(unpaidBookings, unpaidBooking.bookingId);
      setUnpaidBookings(newGuestList);

      // Remove from database
      const userRef = doc(db, 'account', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedRooms = userData.rooms.filter(
          room => room.bookingId !== unpaidBooking.bookingId
        );

        await updateDoc(userRef, { rooms: updatedRooms });
        console.log("Booking removed from database successfully");
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error removing booking:", error);
      // Optionally, revert the local state change if the database update fails
      setUnpaidBookings(unpaidBookings);
    }
  }, [unpaidBookings, userId, setUnpaidBookings]);


  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });
  const getRoomImage = (imageName) => {
    let image;
    switch (imageName) {
      case 'Twin':
        image = 'LeMeridianTwinRoom.jpeg';
        break;
      case 'King':
        image = 'LeMeridianKingRoom.jpeg';
        break;
      default:
        image = 'LeMeridianKingRoom.jpeg';
    }
    return (
      <img
        style={{
          maxWidth: '80%',
          objectFit: 'contain',
        }}
        src={image}
        alt="Room logo"
      />
    );
  };
 
  return unpaidBookings?.map((unpaidBooking, idx) => (
    <React.Fragment key={unpaidBooking.bookingId}>
      <Grid container>
        <Grid item xs={3}>
          {getRoomImage(unpaidBooking.roomType)}
        </Grid>
        <Grid item xs={4}>
          <CartBodyRoomDetails
            setNumCoachTickets={setNumCoachTickets}
            unpaidBooking={unpaidBooking}
          />
        </Grid>
        <Grid item xs={3}>
          <CartBodyItemisedBreakdown
            numCoachTickets={numCoachTickets}
            unpaidBooking={unpaidBooking}></CartBodyItemisedBreakdown>
        </Grid>
        <Button
        sx={{
          margin: 'auto',
        }}
        onClick={() => handleRemoveUnpaidBooking(unpaidBooking)}
        variant="contained"
        color="error">
        Remove
      </Button>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'start',
          }}>
          
        </Grid>
      </Grid>
      <div
        style={{
          width: '100%',
          borderBottom: '2px solid #7E7E7E',
          margin: 'auto',
        }}></div>
    </React.Fragment>
  ));
  

  // return (
  //   <>
  //     <Grid container>
  //       <Grid item xs={4}>
  //         roomImage
  //       </Grid>
  //       <Grid item xs={6}>
  //         Room type, Occupant names, price per room
  //       </Grid>
  //       <Grid item xs={2}>
  //         Remove from cart
  //       </Grid>
  //     </Grid>
  //     <div
  //       style={{
  //         width: '100%',
  //         borderBottom: '2px solid #7E7E7E',
  //         margin: 'auto',
  //       }}></div>{' '}
  //   </>
  // );
}

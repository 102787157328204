import { Box, TextField, Button } from '@mui/material';
import { Container } from '@mui/system';
import HomePageButton from 'components/HomePageButton';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

class ButtonDetails {
  constructor(
    content = 'defaultContent',
    color = 'primary',
    clickHandler = () => {}
  ) {
    this.content = content;
    this.color = color;
    this.clickHandler = clickHandler;
  }
}

export default function EditBookingButtons(props) {

  const navigate = useNavigate();
  const [commentMode, setCommentMode] = useState(false);
  const [editCostMode, setEditCostMode] = useState(false);
  const [comment, setComment] = useState(props.comment || '');
  const [roomCost, setRoomCost] = useState(props.room?.roomCost || 0);
  const [transportCost, setTransportCost] = useState(props.room?.transportCost || 0);

  const addComment = () => {
    setCommentMode(true);
  };

  const editComment = () => {
    props.editComment(comment);
    setCommentMode(false);
  };

  const editRoomCost = () => {
    setEditCostMode(true);
  };

  const saveRoomCost = () => {
    props.editRoomCost(roomCost, transportCost);
    setEditCostMode(false);
  };

  useEffect(() => {
    if (props.room) {
      setRoomCost(props.room.roomCost);
      setTransportCost(props.room.transportCost);
    }
  }, [props.room]);

  function createNavButtons() {
    const buttonDetailsList = [
      new ButtonDetails('Add Comment', 'primary', addComment),
    ];

    if (props.isVerified && !props.loading) {
      buttonDetailsList.push(new ButtonDetails('Verify Payment', 'primary', props.verifyRoomPayment));
    }
    if (props.isVerified && !props.loading) {
      buttonDetailsList.push(new ButtonDetails('Edit Cost', 'primary', editRoomCost));
    }

    return buttonDetailsList.map((buttonDetail) => (
      <React.Fragment key={buttonDetail.content}>
        <Box sx={{ width: '100%', mb: 4 }}>
          <HomePageButton
            color={buttonDetail.color}
            content={buttonDetail.content}
            clickHandler={buttonDetail.clickHandler}
          />
        </Box>
      </React.Fragment>
    ));
  }

  return (
    <Container component="main" maxWidth="sm">
      {!commentMode && !editCostMode ? (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {createNavButtons()}
        </Box>
      ) : commentMode ? (
        <Box
          sx={{
            width: '40vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="comment"
            defaultValue={props.comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={10}
            size="medium"
          />
          <Button
            size="small"
            color="primary"
            sx={{ textAlign: "right" }}
            onClick={editComment}
          >
            Edit Comment
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            width: '40vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="roomCost"
            label="Room Cost"
            value={roomCost}
            onChange={(e) => setRoomCost(e.target.value)}
            size="medium"
            type="number"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="transportCost"
            label="Transport Cost"
            value={transportCost}
            onChange={(e) => setTransportCost(e.target.value)}
            size="medium"
            type="number"
          />
          <Button
            size="small"
            color="primary"
            sx={{ textAlign: "right" }}
            onClick={saveRoomCost}
          >
            Save Room Cost
          </Button>
        </Box>
      )}
    </Container>
  );
}

import { getAuth, signOut } from 'firebase/auth';

export function logout(navigate) {
  const auth = getAuth();

  signOut(auth)
    .then(() => {
      console.info('logged out');
      navigate('/sign-in');
    })
    .catch((error) => {
      console.info('not logged in');
      navigate('/sign-in');
    });
}
